import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'
import { useOpensUpdateMutation } from '../../services/action';
import Button from '@mui/material/Button';
function Opens() {
  const { campId, userId } = useParams();
  const [updatedata, updateresult] = useOpensUpdateMutation()

  useEffect(() => {
    updatedata({
      campId: campId,
      userId: userId
    })
  }, []);



  return (
    <>
    </>
  );
}

export default Opens;
