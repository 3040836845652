import React, { Component, useState } from 'react';
import { Redirect, Link } from "react-router-dom";
import HeaderMenu from '../Pages/HeaderMenu'
import './index.scss';
import { useSelector } from "react-redux";
function Public(props) {

  const Pub = props.pub;


  return (
    <>
      <Pub />
    </>
  );
}

export default Public;
