import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Skeletons() {
  return (
    <>
      <tr className="Order-Table_subcard">
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></td>
      </tr>
      <tr className="Order-Table_subcard">
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></td>
      </tr>
      <tr className="Order-Table_subcard">
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></td>
      </tr>
      <tr className="Order-Table_subcard">
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></td>
      </tr>
      <tr className="Order-Table_subcard">
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></td>
      </tr>
      <tr className="Order-Table_subcard">
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text"><Skeleton variant="rect" /></td>
        <td className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></td>
      </tr>
    </>
  )
}

export default Skeletons;