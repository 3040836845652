import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllCountriesQuery, useDeleteCountriesMutation, useCreateCountriesMutation, useUpdateCountriesMutation } from '../../services/countries';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

function Countries() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const suppliersData = useGetAllCountriesQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.countries;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;




  const [deleteList, deleteresult] = useDeleteCountriesMutation()
  const [createNew, createresult] = useCreateCountriesMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getCode, setCode] = useState("")
  const [getCurrency, setCurrency] = useState("")
  const [getConversion, setConversion] = useState("")
  const [getDiscount1, setDiscount1] = useState("")
  const [getAmount1, setAmount1] = useState("")
  const [getDiscount2, setDiscount2] = useState("")
  const [getAmount2, setAmount2] = useState("")
  const [getDiscount3, setDiscount3] = useState("")
  const [getAmount3, setAmount3] = useState("")
  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setCode(data.code)
    setCurrency(data.currency)
    setConversion(data.conversion)
    setDiscount1(data.discount1)
    setAmount1(data.amount1)
    setDiscount2(data.discount2)
    setAmount2(data.amount2)
    setDiscount3(data.discount3)
    setAmount3(data.amount3)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    code: getCode,
    currency: getCurrency,
    conversion: getConversion,
    discount1: getDiscount1,
    amount1: getAmount1,
    discount2: getDiscount2,
    amount2: getAmount2,
    discount3: getDiscount3,
    amount3: getAmount3,
    userId: userId,
    userToken: userToken,
    admin: admin
  }
  const [updatePost, updateresult] = useUpdateCountriesMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };

  return (
    <div className="Order-style">
      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createNew)}>
            <div className="row">
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-6">
                <div className="form-group">
                  <label>Country Name</label>
                  <input type="text" className="form-control dlrdflInput" id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Code</label>
                  <input type="text" className="form-control dlrdflInput" id="code"
                    aria-invalid={errors.code ? "true" : "false"}
                    {...register('code', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Currency</label>
                  <input type="text" className="form-control dlrdflInput" id="currency"
                    aria-invalid={errors.currency ? "true" : "false"}
                    {...register('currency', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Conversion</label>
                  <input type="number" className="form-control dlrdflInput" id="conversion"
                    aria-invalid={errors.conversion ? "true" : "false"}
                    {...register('conversion', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Discount1</label>
                  <input type="number" className="form-control dlrdflInput" id="discount1"
                    aria-invalid={errors.discount1 ? "true" : "false"}
                    {...register('discount1', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Amount1</label>
                  <input type="number" className="form-control dlrdflInput" id="amount1"
                    aria-invalid={errors.amount1 ? "true" : "false"}
                    {...register('amount1', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Discount2</label>
                  <input type="number" className="form-control dlrdflInput" id="discount2"
                    aria-invalid={errors.discount2 ? "true" : "false"}
                    {...register('discount2', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Amount2</label>
                  <input type="number" className="form-control dlrdflInput" id="amount2"
                    aria-invalid={errors.amount2 ? "true" : "false"}
                    {...register('amount2', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Discount3</label>
                  <input type="number" className="form-control dlrdflInput" id="discount3"
                    aria-invalid={errors.discount3 ? "true" : "false"}
                    {...register('discount3', { required: true })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Amount3</label>
                  <input type="number" className="form-control dlrdflInput" id="amount3"
                    aria-invalid={errors.amount3 ? "true" : "false"}
                    {...register('amount3', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">

                  <Button
                    color="info"
                    variant="contained"
                    size="medium"
                    disabled={createresult?.isLoading ? true : false}
                    type="submit"
                    style={{ width: '100%', height: '45px' }}
                  >
                    {createresult?.isLoading ? 'loading...' : 'Create'}
                  </Button>

                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Country Name</label>
                <input type="text" className="form-control dlrdflInput" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Code</label>
                <input type="text" className="form-control dlrdflInput" value={getCode}
                  onChange={(e) => setCode(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Currency</label>
                <input type="text" className="form-control dlrdflInput" value={getCurrency}
                  onChange={(e) => setCurrency(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Conversion</label>
                <input type="number" className="form-control dlrdflInput" value={getConversion}
                  onChange={(e) => setConversion(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount1</label>
                <input type="number" className="form-control dlrdflInput" value={getDiscount1}
                  onChange={(e) => setDiscount1(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Amount1</label>
                <input type="text" className="form-control dlrdflInput" value={getAmount1}
                  onChange={(e) => setAmount1(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount2</label>
                <input type="number" className="form-control dlrdflInput" value={getDiscount2}
                  onChange={(e) => setDiscount2(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Amount2</label>
                <input type="text" className="form-control dlrdflInput" value={getAmount2}
                  onChange={(e) => setAmount2(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Discount3</label>
                <input type="number" className="form-control dlrdflInput" value={getDiscount3}
                  onChange={(e) => setDiscount3(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Amount3</label>
                <input type="text" className="form-control dlrdflInput" value={getAmount3}
                  onChange={(e) => setAmount3(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  disabled={updateresult?.isLoading ? true : false}
                  onClick={() => { updatePost(updateData) }}>
                  {updateresult?.isLoading ? 'loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                    <SearchIcon className="table_search_icon" />
                  </div>

                  {(suppliersData?.isFetching) && (!suppliersData?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>

                <div className="dFlex center nowrap">
                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => deleteList({
                      status: checked?.status === 0 ? 1 : 0,
                      id: checked?.id,
                      userId: userId,
                      userToken: userToken,
                      admin: admin
                    })}
                  >
                    {deleteresult?.isLoading && deleteresult?.originalArgs?.id === checked?.id ?
                      <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      :
                      'Delete'
                    }
                  </Button>
                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => handleOpenEdit({
                      status: true,
                      name: checked?.name,
                      code: checked?.code,
                      currency: checked?.currency,
                      conversion: checked?.conversion,
                      discount1: checked?.discount1,
                      amount1: checked?.amount1,
                      discount2: checked?.discount2,
                      amount2: checked?.amount2,
                      discount3: checked?.discount3,
                      amount3: checked?.amount3,
                      id: checked?.id
                    })}
                  >
                    Edit
                  </Button>
                  <Button
                    color="warning"
                    variant="contained"
                    size="medium"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>

                </div>
              </div>



              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700" style={{ width: '0px' }}></div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">Code</div>
                    <div className="Order-Table_item heading fw700">Currency</div>
                    <div className="Order-Table_item heading fw700">Conversion</div>
                    <div className="Order-Table_item heading fw700">Dis.1</div>
                    <div className="Order-Table_item heading fw700">Amount1</div>
                    <div className="Order-Table_item heading fw700">Dis.2</div>
                    <div className="Order-Table_item heading fw700">Amount2</div>
                    <div className="Order-Table_item heading fw700">Dis.3</div>
                    <div className="Order-Table_item heading fw700">Amount3</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Created</div>
                  </div>
                  {suppliersData?.isLoading &&
                    <Skeletons />
                  }
                  {suppliers?.map((item, i) =>
                    <div className="Order-Table_subcard">
                      <div className="Order-Table_item text" style={{ width: '0px' }}>
                        <Checkbox
                          size="small"
                          checked={checked?.id === item.id ? checked?.event : false}
                          onChange={((event) => handleChange({
                            event: event.target.checked,
                            name: item.name,
                            code: item.code,
                            currency: item.currency,
                            conversion: item.conversion,
                            discount1: item.discount1,
                            amount1: item.amount1,
                            discount2: item.discount2,
                            amount2: item.amount2,
                            discount3: item.discount3,
                            amount3: item.amount3,
                            id: item.id
                          }))}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                      <div className="Order-Table_item text">
                        {item.name}
                      </div>
                      <div className="Order-Table_item text">{item.code}</div>
                      <div className="Order-Table_item text">{item.currency}</div>
                      <div className="Order-Table_item text">{item.conversion}</div>
                      <div className="Order-Table_item text">{item.discount1}</div>
                      <div className="Order-Table_item text">{item.amount1}</div>
                      <div className="Order-Table_item text">{item.discount2}</div>
                      <div className="Order-Table_item text">{item.amount2}</div>
                      <div className="Order-Table_item text">{item.discount3}</div>
                      <div className="Order-Table_item text">{item.amount3}</div>
                      <div className="Order-Table_item text sticky-column">
                        <div className="dFlex center nowrap jcr">
                          {moment(item.createdAt).local().format("MMM Do YYYY")}
                        </div>
                      </div>
                    </div>
                  )}


                </div>
                {suppliers?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Countries;
