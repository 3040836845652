import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import CloseIcon from "@material-ui/icons/Close";
import Button from '@mui/material/Button';

function ImageUpdate(props) {

  function buttonDisabled() {
    if (props.checkSize) {
      return true
    } else if (!props.getUpdateImage) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Modal
        className="image-update-modal"
        show={props.getImageUpdateModal}
        onHide={props.closeImageUpdate}
        animation={false}
        backdrop={props.imageresult?.isLoading ? 'static' : 'dynamic'}
      >
        {!props.imageresult?.isLoading &&
          <div className="close-button" onClick={props.closeImageUpdate} ><CloseIcon /></div>
        }
        <Modal.Body>
          <div>{props.getImageError && props.getImageError}</div>
          <div className="row">
            <div className="col-md-12">
              <h5>Update Media</h5>
              <br />
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input type="file" className="form-control dlrdflInput"
                  onChange={(e) => props.setUpdateImage(e.target.files[0])} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'center' }}>
                {props.imageresult.isLoading ?
                  <>
                    <Spinner style={{ width: '2rem', height: '2rem', fontSize: '7px', marginBottom: 10 }} animation="border" />
                    <h5>uploading... don't close or reload the browser </h5>
                  </>

                  :
                  <Button
                    style={{ width: '100%' }}
                    disabled={buttonDisabled()}
                    color="info"
                    variant="contained"
                    size="medium"
                    onClick={props.updateBookImage}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageUpdate;
