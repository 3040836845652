import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Skeletons() {
  return (
    <>
      <div className="Order-Table_subcard">
        <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
        <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
        <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
        <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
        <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
        <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
        <div className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></div>
      </div>
    </>
  )
}

export default Skeletons;