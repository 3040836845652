import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllSubscribersQuery, useDeleteSubscribersMutation, useUpdateSubscribersMutation } from '../../services/search';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Tag } from 'antd';
import { Menu, Dropdown } from 'antd';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function Search() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { emailid } = useParams();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const usersData = useGetAllSubscribersQuery({ emailid: emailid, page: page, size: limit, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersData?.data?.users;
  const pagination = usersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteSubscribersMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getEmail, setEmail] = useState("")
  const [getMobile, setMobile] = useState("")
  const [getCountryCode, setCountryCode] = useState("")
  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setEmail(data.email)
    setMobile(data.mobile)
    setCountryCode(data.country_code)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    mobile: getMobile,
    email: getEmail,
    country_code: getCountryCode,
    userId: userId, userToken: userToken, admin: admin
  }
  const [updatePost, updateresult] = useUpdateSubscribersMutation()



  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };


  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }

    if (deleteresult?.isSuccess) {
      setChecked([])
    }

  }, [updateresult, deleteresult]);



  function unsubscribeDrop() {
    if (checked?.event) {
      if (checked?.subscribed) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  function subscribeDrop() {
    if (checked?.event) {
      if (checked?.subscribed) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <div className="Order-style">

      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control dlrdflInput" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control dlrdflInput" value={getEmail}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Mobile</label>
                <input type="number" className="form-control dlrdflInput" value={getMobile}
                  onChange={(e) => setMobile(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Country Code</label>
                <input type="text" className="form-control dlrdflInput" value={getCountryCode}
                  onChange={(e) => setCountryCode(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  disabled={updateresult?.isLoading ? true : false}
                  onClick={() => { updatePost(updateData) }}>
                  {updateresult?.isLoading ? 'loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                    <SearchIcon className="table_search_icon" />
                  </div> */}

                  {(usersData?.isFetching) && (!usersData?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>

                <div className="dFlex center nowrap">


                  <Dropdown
                    style=""
                    className="Header-waller-connect2 mr10"
                    overlay={(
                      <Menu className="Header-dropdown-menu">
                        <Menu.Item
                          key="0"
                          disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.unsubscribe === 'unsubscribe' ? true : unsubscribeDrop()}
                          onClick={() => deleteList({
                            subscribed: false,
                            id: checked?.id,
                            userId: userId,
                            userToken: userToken,
                            admin: admin,
                            subscribe: 'subscribe'
                          })}
                        >
                          Unsubscribe
                        </Menu.Item>
                        <Menu.Item
                          key="1"
                          disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.subscribe === 'subscribe' ? true : subscribeDrop()}
                          onClick={() => deleteList({
                            subscribed: true,
                            id: checked?.id,
                            userId: userId,
                            userToken: userToken,
                            admin: admin,
                            subscribe: 'subscribe'
                          })}
                        >
                          Subscribe
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.permanent_delete === 'permanent_delete' ? true : checked?.event ? false : true}
                          onClick={() => {
                            if (window.confirm('Are you sure to permanent delete this user?')) deleteList({
                              id: checked?.id,
                              userId: userId,
                              userToken: userToken,
                              admin: admin,
                              permanent_delete: 'permanent_delete'

                            })
                          }}
                        >
                          Permanent Delete
                        </Menu.Item>
                      </Menu>
                    )} trigger={['click']}>
                    <div onClick={e => e.preventDefault()} style={{ display: 'flex', alignItems: 'center', border: '1px solid rgba(0, 0, 0, 0.12)', padding: '3px 10px 3px 10px', borderRadius: '4px', justifyContent: 'space-between', width: 'auto', cursor: 'pointer' }}>
                      <div style={{ color: '#7b8087' }}>Action</div>
                      <IconButton aria-haspopup="true" style={{ padding: '0' }}>
                        <KeyboardArrowDownIcon style={{ color: '#1e2f438f' }} />
                      </IconButton>
                    </div>
                  </Dropdown>


                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="small"
                    className="mr10"
                    onClick={() => handleOpenEdit({
                      status: true,
                      name: checked?.name,
                      mobile: checked?.mobile,
                      email: checked?.email,
                      country_code: checked?.country_code,
                      id: checked?.id
                    })}
                  >
                    Edit
                  </Button>
                  {/* <Button
                    color="warning"
                    variant="contained"
                    size="small"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button> */}

                </div>
              </div>



              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700" style={{ width: '0px' }}></div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">Email</div>
                    <div className="Order-Table_item heading fw700">Mobile</div>
                    <div className="Order-Table_item heading fw700">Country</div>
                    <div className="Order-Table_item heading fw700 sticky-column">Created</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                  </div>
                  {(usersData?.isLoading) || (usersData?.isFetching) ?
                    <Skeletons />
                    :
                    <>
                      {users?.map((item, i) =>
                        <div className="Order-Table_subcard">
                          <div className="Order-Table_item text" style={{ width: '0px' }}>
                            <Checkbox
                              size="small"
                              checked={checked?.id === item.id ? checked?.event : false}
                              onChange={((event) => handleChange({
                                event: event.target.checked,
                                name: item.name,
                                mobile: item.mobile,
                                email: item.email,
                                country_code: item.country_code,
                                id: item.id,
                                subscribed: item.subscribed,
                                bounced: item.bounced
                              }))}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                          <div className="Order-Table_item text">
                            {item.name}
                          </div>
                          <div className="Order-Table_item text">{item.email}</div>
                          <div className="Order-Table_item text">{item.mobile}</div>
                          <div className="Order-Table_item text">{item.country_code}</div>
                          <div className="Order-Table_item text">{moment(item.createdAt).local().format("MMM Do YYYY")}</div>
                          <div className="Order-Table_item text sticky-column">
                            <div className="dFlex center nowrap jcr">
                              {item.bounced ?
                                <Tag color={"#fed374"} style={{ color: '#1e2f43', margin: 0 }}>
                                  {'Bounced'}
                                </Tag> :
                                <Tag color={item.subscribed ? "#72c585" : "#eb8188"} style={{ color: item.subscribed && '#fcfcfc', margin: 0 }}>
                                  {item.subscribed ? 'Subscribed' : 'Unsubscribed'}
                                </Tag>
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  }


                </div>
                {users?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
