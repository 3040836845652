import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetCampaignQuery, useDeleteCampaignsMutation, useCreateCampaignsMutation, useUpdateCampaignsMutation, useFetchQuery, useGetAllSegmentsQuery } from '../../services/campaign';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tag } from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DoneIcon from '@mui/icons-material/Done';
var moment = require('moment');
function Campaign() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const { campaign_id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const suppliersData = useGetCampaignQuery({ id: campaign_id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const Campaign = suppliersData?.data?.campaign;



  const templateInfo = useFetchQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const templates = templateInfo?.data?.templates;
  const templatesPagination = templateInfo?.data?.pagination;


  const segmentsInfo = useGetAllSegmentsQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const segments = segmentsInfo?.data?.segments;
  const segmentsPagination = segmentsInfo?.data?.pagination;



  const [getTestEmail, setTestEmail] = useState("")




  const [deleteList, deleteresult] = useDeleteCampaignsMutation()
  const [createNew, createresult] = useCreateCampaignsMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getSubject, setSubject] = useState("")
  const [getFromName, setFromName] = useState("")
  const [getFromEmail, setFromEmail] = useState("")
  const [getReplyToEmail, setReplyToEmail] = useState("") 
  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setSubject(data.subject)
    setFromName(data.from_name)
    setFromEmail(data.from_email)
    setReplyToEmail(data.reply_toemail) 
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    subject: getSubject,
    from_name: getFromName,
    from_email: getFromEmail,
    reply_toemail: getReplyToEmail, 
    userId: userId,
    userToken: userToken,
    admin: admin
  }
  const [updatePost, updateresult] = useUpdateCampaignsMutation()

  function updateSegment(data) {
    updatePost({
      userId: userId,
      userToken: userToken,
      admin: admin,
      id: data.campaign_id,
      segment_id: data.segment_id,
      select_segment: data.select_segment,
      change_segment: data.change_segment
    })
  }
  function updateTemplate(data) {
    updatePost({
      userId: userId,
      userToken: userToken,
      admin: admin,
      id: data.campaign_id,
      template_id: data.template_id,
      subject: data.subject,
      template_html: data.template_html,
      change_template: data.change_template,
      select_template: data.select_template,
      template_name: data.template_name
    })
  }

  function ScheduleDateUpdate(data) {
    updatePost({
      userId: userId,
      userToken: userToken,
      admin: admin,
      id: data.campaign_id,
      schedule_date: data.schedule_date
    })
  }

  function startSchedule(data) {
    updatePost({
      userId: userId,
      userToken: userToken,
      admin: admin,
      id: data.campaign_id,
      scheduled: data.scheduled
    })
  }
  function UnSchedule(data) {
    updatePost({
      userId: userId,
      userToken: userToken,
      admin: admin,
      id: data.campaign_id,
      scheduled: data.scheduled,
      schedule_date: data.schedule_date,
      unscheduled: data.unscheduled,
    })
  }





  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }

    if (deleteresult?.isSuccess) {
      setChecked([])
    }
  }, [updateresult, createresult, deleteresult]);


  function testEmailDisable() {
    if (createresult?.isLoading) {
      return true
    } else if (!getTestEmail) {
      return true
    } else if (getTestEmail) {
      return false
    } else {
      return true
    }
  }


  const [getViewTemp, setViewTemp] = React.useState(false);
  const [getTemplate, setTemplate] = React.useState("");

  const ViewTemplateModal = (data) => {
    setViewTemp(true);
    setTemplate(data);
  };
  const closeViewTemp = () => {
    setViewTemp(false);
  };


  function checkEmptyDetails() {
    if (!Campaign?.from_email) {
      return true
    } else if (!Campaign?.subject) {
      return true
    } else {
      return false
    }
  }



  function updateButtonDisabled() {
    if (Campaign?.scheduled) {
      return true
    } else if (updateresult?.isLoading) {
      return true
    } else if (Campaign?.send_status) {
      return true
    } else {
      return false
    }
  }



  return (
    <div className="Order-style">

      <Modal className="new-post-modal" show={getViewTemp} onHide={closeViewTemp} centered animation={false}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="post-value"
                dangerouslySetInnerHTML={{
                  __html: getTemplate
                }}
              >
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Compaign Name</label>
                <input type="text" className="form-control dlrdflInput" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Subject</label>
                <input type="text" className="form-control dlrdflInput" value={getSubject}
                  onChange={(e) => setSubject(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>From Email</label>
                <input type="email" className="form-control dlrdflInput" value={getFromEmail}
                  onChange={(e) => setFromEmail(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>From Name</label>
                <input type="text" className="form-control dlrdflInput" value={getFromName}
                  onChange={(e) => setFromName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Reply to email?</label>
                <input type="email" className="form-control dlrdflInput" value={getReplyToEmail}
                  onChange={(e) => setReplyToEmail(e.target.value)} />
              </div>
            </div> 
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  disabled={updateresult?.isLoading ? true : false}
                  onClick={() => { updatePost(updateData) }}>
                  {updateresult?.isLoading ? 'loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="container">
        <div className="row" style={{justifyContent: 'center'}}>

          <div className="col-md-8">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>


              {suppliersData?.isLoading ?
                <div className="Order-Top_navbar" style={{ display: 'block' }}>
                  <Skeleton variant="rect" height={`10px`} />
                  <Skeleton variant="rect" height={`10px`} />
                  <Skeleton variant="rect" height={`10px`} />
                  <Skeleton variant="rect" height={`10px`} />
                </div>
                :


                <>
                  {Campaign?.template_id ?
                    <div className="Order-Top_navbar" style={{ borderBottom: '1px solid #eee' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="mr10">
                            <input type="email" className="form-control table_search" placeholder="Email ID" onChange={(e) => setTestEmail(e.target.value)} />
                          </div>
                          <div>
                            <Button
                              disabled={testEmailDisable()}
                              color="info"
                              variant="contained"
                              size="medium"
                              onClick={(() => createNew({
                                id: Campaign?.id,
                                email: getTestEmail,
                                userId: userId,
                                userToken: userToken,
                                admin: admin,
                              }))}
                            >
                              {createresult?.isLoading ? 'Loading...' : 'Send test Email'}
                            </Button>
                            {createresult?.data?.success && <DoneIcon style={{ fontSize: '40px', paddingLeft: '10px', color: '#0caa26' }} />}
                          </div>
                        </div>


                        {!Campaign?.send_status ?
                          <>
                            {Campaign?.starts ?
                              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '7px' }}>
                                <span style={{ paddingRight: '5px' }}>Sending...</span>
                                <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px' }} animation="border" />
                              </div> : ''
                            }
                          </> : ''
                        }


                      </div>
                    </div>
                    :
                    ''
                  }


                  {Campaign?.template_id && Campaign?.segment_id ?

                    <>

                      {!Campaign?.schedule_date &&
                        <div className="Order-Top_navbar" style={{ borderBottom: '1px solid #eee' }}>
                          <div style={{ width: '100%' }}>
                            <h5 style={{ paddingBottom: '5px' }}>Select Date and Time</h5>
                            <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect timeFormat="HH:mm" timeIntervals={5} timeCaption="time" dateFormat="MMMM d, yyyy h:mm aa" inline
                            />
                            <Button
                              color={`info`}
                              variant="contained"
                              size="mideum"
                              style={{ width: '100%' }}
                              onClick={(() => ScheduleDateUpdate({
                                schedule_date: moment(startDate).local().format("YYYY-MM-DD hh:mm"),
                                campaign_id: Campaign?.id,
                              }))}
                            >
                              {(updateresult?.isLoading) && (updateresult?.originalArgs?.schedule_date) ? 'Loading...' : `Next`}
                            </Button>
                          </div>
                        </div>
                      }


                      {(Campaign?.schedule_date) &&
                        <>
                          {(!Campaign?.scheduled) && (!Campaign?.send_status) ?
                            <div className="Order-Top_navbar" style={{ borderBottom: '1px solid #eee', width: '100%', justifyContent: 'center', whiteSpace: 'normal' }}>
                              <div style={{ textAlign: 'center' }}>
                                <h3 style={{ paddingBottom: '5px' }}>
                                  {Campaign?.schedule_date && moment.utc(Campaign?.schedule_date).format("MMM Do YYYY hh:mm")}
                                </h3>
                                <br />
                                <Button
                                  disabled={checkEmptyDetails()}
                                  color={`info`}
                                  variant="contained"
                                  size="mideum"
                                  className="mr10"
                                  onClick={(() => startSchedule({
                                    scheduled: true,
                                    campaign_id: Campaign?.id,
                                  }))}
                                >
                                  {(updateresult?.isLoading) && (updateresult?.originalArgs?.scheduled) ? 'Loading...' : `Schedule Now`}
                                </Button>
                                <Button
                                  color={`warning`}
                                  variant="contained"
                                  size="mideum"
                                  onClick={(() => UnSchedule({
                                    scheduled: false,
                                    unscheduled: true,
                                    schedule_date: 'NULL',
                                    campaign_id: Campaign?.id,
                                  }))}
                                >
                                  {(updateresult?.isLoading) && (updateresult?.originalArgs?.unscheduled) ? 'Loading...' : `Change Date`}
                                </Button>
                              </div>
                            </div> : ''}


                          {(Campaign?.scheduled) ?
                            <>

                              {!Campaign?.send_status ?
                                <>
                                  {Campaign?.starts ?
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '20px', justifyContent: 'center' }}>
                                      <span style={{ paddingRight: '5px' }}>Sending...</span>
                                      <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px' }} animation="border" />
                                    </div> : ''
                                  }
                                </> : ''
                              }
                              {!Campaign?.starts ?

                                <div className="Order-Top_navbar" style={{ borderBottom: '1px solid #eee', width: '100%', justifyContent: 'center', whiteSpace: 'normal' }}>
                                  <div style={{ textAlign: 'center' }}>
                                    <h6 style={{ paddingBottom: '5px' }}>
                                      {'Campaign has been scheduled ON'}
                                    </h6>
                                    <h3>{moment.utc(Campaign?.schedule_date).format("MMM Do YYYY hh:mm")}</h3>
                                    <br />

                                    <Button
                                      disabled={false}
                                      color={`warning`}
                                      variant="contained"
                                      size="mideum"
                                      className="mr10"
                                      onClick={(() => UnSchedule({
                                        scheduled: false,
                                        unscheduled: true,
                                        schedule_date: 'NULL',
                                        campaign_id: Campaign?.id,
                                      }))}
                                    >
                                      {updateresult?.isLoading ? 'Loading...' : `Change Date`}
                                    </Button>
                                  </div>
                                </div> : ''}
                            </> : ''}




                          {(Campaign?.send_status) ?
                            <>
                              <div className="Order-Top_navbar" style={{ borderBottom: '1px solid #eee', width: '100%', justifyContent: 'center', whiteSpace: 'normal' }}>
                                <div style={{ textAlign: 'center' }}>

                                  <h6 style={{ paddingBottom: '5px' }}>
                                    {'Campaign has been sent ON'}
                                  </h6>
                                  <h3>{Campaign?.send_date && moment.utc(Campaign?.send_date).format("MMM Do YYYY hh:mm")}</h3>
                                  <br />

                                  <Button
                                    disabled={true}
                                    color={`warning`}
                                    variant="contained"
                                    size="mideum"
                                    className="mr10"
                                  >
                                    {'Sent'}
                                  </Button>
                                  <Button
                                    color={`info`}
                                    variant="contained"
                                    size="mideum"
                                    onClick={(() => history.push(`/report/${Campaign?.id}?name=${Campaign?.name}`))}
                                  >
                                    {`Report`}
                                  </Button>
                                </div>
                              </div>
                            </> : ''}
                        </>
                      }
                    </> : ''


                  }


                  {(!Campaign?.segment_id) && (Campaign?.template_id) ?
                    <>
                      <div className="Order-Top_navbar">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {segmentsInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                            <div className="table_search_card">
                              <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                              <SearchIcon className="table_search_icon" />
                            </div>
                          }
                        </div>

                        <div className="dFlex center nowrap">
                          {segmentsInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : <h5>Select Segments</h5>}
                        </div>
                      </div>
                      <div className="Order-Table_view">
                        <div className="Order-Table_card">
                          <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                            <div className="Order-Table_item heading fw700">Name</div>
                            <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                          </div>
                          {segmentsInfo?.isLoading &&
                            <div className="Order-Table_subcard">
                              <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
                              <div className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></div>
                            </div>
                          }

                          {segments?.map((item, i) =>
                            <div className="Order-Table_subcard">
                              <div className="Order-Table_item text">
                                <Link to={`/segment/${item.id}?name=${item.name}`} disabled={item.status ? false : true}>
                                  {item.name}
                                </Link>
                              </div>
                              <div className="Order-Table_item text sticky-column">
                                <div className="dFlex center nowrap jcr">
                                  <Button
                                    color="warning"
                                    variant="contained"
                                    size="small"
                                    disabled={item.status ? false : true}
                                    onClick={(() => updateSegment({
                                      segment_id: item.id,
                                      campaign_id: Campaign?.id,
                                      select_segment: true,
                                    }))}
                                  >
                                    <small className="dFlex">{(updateresult?.isLoading) && (updateresult?.originalArgs?.segment_id === item.id) ? 'Loading...' : 'Select'}</small>
                                  </Button>

                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {segments?.length === 0 &&
                          <h1 className="Order-no_data_found">Not Data Found!</h1>
                        }
                        <div className="Order-Pagination">
                          <Pagination
                            style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                            page={parseInt(page)}
                            count={parseInt(segmentsPagination?.totalPages)}
                            color="primary"
                            variant="outlined"
                            renderItem={(item) => (
                              <PaginationItem
                                component={Link}
                                to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                                {...item}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </> : ''
                  }




                  {!Campaign?.template_id ?
                    <>
                      <div className="Order-Top_navbar">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {templateInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                            <div className="table_search_card">
                              <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                              <SearchIcon className="table_search_icon" />
                            </div>
                          }
                        </div>
                        <div className="dFlex center nowrap">
                          {templateInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : <h5>Select Template</h5>}
                        </div>
                      </div>
                      <div className="Order-Table_view">
                        <div className="Order-Table_card">
                          <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                            <div className="Order-Table_item heading fw700">Name</div>
                            <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                          </div>
                          {templateInfo?.isLoading &&
                            <div className="Order-Table_subcard">
                              <div className="Order-Table_item text"><Skeleton variant="rect" /></div>
                              <div className="Order-Table_item text sticky-column"><Skeleton variant="rect" /></div>
                            </div>
                          }

                          {templates?.map((item, i) =>
                            <div className="Order-Table_subcard">
                              <div className="Order-Table_item text">
                                <Link to={`#`} style={{ pointerEvents: 'none' }} disabled={item.status ? false : true}>
                                  {item.name}
                                </Link>
                              </div>
                              <div className="Order-Table_item text sticky-column">
                                <div className="dFlex center nowrap jcr">
                                  <Button
                                    disabled={item.status ? false : true}
                                    color={`warning`}
                                    variant="contained"
                                    size="small"
                                    onClick={(() => updateTemplate({
                                      template_id: item.id,
                                      campaign_id: Campaign?.id,
                                      template_html: item.template_html,
                                      subject: Campaign?.subject,
                                      template_name: item.name,
                                      select_template: true,
                                    }))}
                                  >
                                    <small className="dFlex">{(updateresult?.isLoading) && (updateresult?.originalArgs?.template_id === item.id) ? 'Loading...' : `Select`}</small>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {templates?.length === 0 &&
                          <h1 className="Order-no_data_found">Not Data Found!</h1>
                        }
                        <div className="Order-Pagination">
                          <Pagination
                            style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                            page={parseInt(page)}
                            count={parseInt(templatesPagination?.totalPages)}
                            color="primary"
                            variant="outlined"
                            renderItem={(item) => (
                              <PaginationItem
                                component={Link}
                                to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                                {...item}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </> : ''
                  }
                </>
              }
             
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  {(suppliersData?.isFetching) && (!suppliersData?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>

                <div className="dFlex center nowrap">
                  {suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                    <Button
                      disabled={updateButtonDisabled()}
                      color="warning"
                      variant="contained"
                      size="small"
                      onClick={() => handleOpenEdit({
                        status: true,
                        name: Campaign?.name,
                        subject: Campaign?.subject,
                        from_name: Campaign?.from_name,
                        from_email: Campaign?.from_email,
                        reply_toemail: Campaign?.reply_toemail,
                        id: Campaign?.id
                      })}
                    >
                      Edit
                    </Button>
                  }

                </div>
              </div>



              <div className="row">
                <div className="col-md-12">
                  <div className="Order-Table_view">
                    <div className="Order-Table_card">
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Campaign Name</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.name}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Campaign Subject</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.subject}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">From Email</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.from_email}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">From Name</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.from_name}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Reply to email</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.reply_toemail}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">To send</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.to_send}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">To contacts</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.to_contacts}</div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Scheduled</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>
                          {suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                            <>
                              {Campaign?.send_status ?
                                <Tag color={"#72c585"} style={{ color: '#fcfcfc', margin: 0 }}>
                                  {'Sent'}
                                </Tag> :
                                <Tag color={Campaign?.scheduled ? "#72c585" : "#ffd577"} style={{ color: Campaign?.scheduled ? '#fcfcfc' : '#444', margin: 0 }}>
                                  {Campaign?.scheduled ? 'Scheduled' : 'Pending'}
                                </Tag>
                              }
                            </>
                          }
                        </div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Schedule Date</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>
                          <h6>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.schedule_date && moment.utc(Campaign?.schedule_date).format("MMM Do YYYY hh:mm")}</h6>
                        </div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Send Status</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>
                          {suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                            <Tag color={Campaign?.send_status ? "#72c585" : "#ffd577"} style={{ color: Campaign?.send_status ? '#fcfcfc' : '#444', margin: 0 }}>
                              {Campaign?.send_status ? 'Sent' : 'Pending'}
                            </Tag>
                          }
                        </div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Send Date</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>
                          {suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.send_date && moment.utc(Campaign?.send_date).format("MMM Do YYYY")}
                        </div>
                      </div> 
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Segment</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>
                          <div>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.segment?.name}</div>
                          {suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                            <>
                              {Campaign?.segment_id ?
                                <Button
                                  disabled={updateButtonDisabled()}
                                  color="warning"
                                  variant="contained"
                                  size="small"
                                  onClick={(() => updateSegment({
                                    segment_id: '0',
                                    campaign_id: Campaign?.id,
                                    change_segment: true,
                                  }))}
                                >
                                  <small className="dFlex">{(updateresult?.isLoading) && (updateresult?.originalArgs?.segment_id) ? 'Loading...' : 'Change'}</small>
                                </Button> : ''
                              }
                            </>
                          }
                        </div>
                      </div>
                      <div className="Order-Table_subcard">
                        <div className="Order-Table_item heading fw700">Template</div>
                        <div className="Order-Table_item heading" style={{ textAlign: 'right' }}>
                          <div>{suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : Campaign?.template?.name}</div>
                          {suppliersData?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                            <>
                              {Campaign?.template_id ?
                                <>
                                  <Button
                                    disabled={updateButtonDisabled()}
                                    color={`warning`}
                                    variant="contained"
                                    size="small"
                                    className="mr10"
                                    onClick={(() => updateTemplate({
                                      template_id: '0',
                                      campaign_id: Campaign?.id,
                                      subject: Campaign?.subject,
                                      template_name: Campaign?.template?.name,
                                      change_template: true
                                    }))}
                                  >
                                    <small className="dFlex">{(updateresult?.isLoading) && (updateresult?.originalArgs?.template_id) ? 'Loading...' : `Change`}</small>
                                  </Button>
                                  <Button
                                    onClick={() => ViewTemplateModal(Campaign?.template?.template_html)}
                                    color={`info`}
                                    variant="contained"
                                    size="small"
                                  >
                                    <small className="dFlex">{`View Template`}</small>
                                  </Button>
                                </>
                                : ''
                              }
                            </>

                          }
                        </div>
                      </div>


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Campaign;
