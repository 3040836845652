import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";

import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/users';
import { message, Switch as Clickable } from "antd";
import Skeletons from './Skeletons'
import moment from 'moment'

import { Menu, Dropdown, Tag } from 'antd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
import PaginationItem from '@mui/material/PaginationItem';
function Users() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10


  const [getNewModal, setNewModal] = useState(false)
  const [getEmail, setEmail] = useState('')
  const [getPassword, setPassword] = useState('')

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    searchquery: getSearchQuery,
    userId: userId, userToken: userToken, admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;

  const [createUser, createresult] = useCreateUserMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getPhone, setPhone] = useState("")
  const [getAddress, setAddress] = useState("")
  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setEmail(data.email)
    setPhone(data.phone)
    setAddress(data.address)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    phone: getPhone,
    email: getEmail,
    address: getAddress,
    userId: userId, userToken: userToken, admin: admin
  }
  const [updateUser, updateresult] = useUpdateUserMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  return (
    <div className="Order-style">

      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {createresult?.error &&
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="alert alert-danger" style={{ textAlign: 'center' }}>{createresult?.error?.data?.error}</div>
                </div>
              </div>
            </div>
          }

          <form onSubmit={handleSubmit(createUser)}>
            <div className="row">
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control dlrdflInput"
                    id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" className="form-control dlrdflInput"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="number" className="form-control dlrdflInput"
                    id="phone"
                    aria-invalid={errors.phone ? "true" : "false"}
                    {...register('phone', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" autoComplete="off" className="form-control dlrdflInput"
                    id="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                  {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Button
                    disabled={createresult.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="medium"
                    style={{ width: '100%', height: '45px' }}
                    type="submit">
                    {createresult.isLoading ? 'loading...' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>



      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                    <SearchIcon className="table_search_icon" />
                  </div>

                  {(usersInfo?.isFetching) && (!usersInfo?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
                <div className="dFlex center nowrap">
                  <Button
                    color="warning"
                    variant="contained"
                    size="medium"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>

                </div>
              </div>


              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700 tac" style={{ width: '1px' }}></div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">Email</div>
                    <div className="Order-Table_item heading fw700">Role</div>
                    <div className="Order-Table_item heading fw700">Phone</div>

                    <div className="Order-Table_item heading fw700">Created</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                  </div>
                  {usersInfo?.isLoading &&
                    <Skeletons />
                  }
                  {users?.map((item, i) =>
                    <div className="Order-Table_subcard">
                      <div className="Order-Table_item text  tac" style={{ width: '1px' }}>
                        <Dropdown className="Header-waller-connect2" overlay={
                          <Menu className="Header-dropdown-menu">
                            {userEmail === 'admin@admin.com' &&
                              <MenuItem key={i} onClick={() => item.email !== 'admin@admin.com' && updateUser({
                                id: item.id,
                                admin_update: true,
                                admin_status: item.admin ? false : true,
                                userId: userId,
                                userToken: userToken,
                                admin: admin
                              })}> {item.admin ? 'Remove Admin' : 'Make Admin'} </MenuItem>
                            }
                          </Menu>
                        } trigger={['click']}>
                          <a onClick={e => e.preventDefault()}>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </a>
                        </Dropdown>
                      </div>
                      <div className="Order-Table_item text">{item.name}</div>
                      <div className="Order-Table_item text">{item.email}</div>
                      <div className="Order-Table_item text">
                        <Tag color={item.admin === 0 ? "#ffd577" : "#78c2d0"} style={{ color: item.admin === 0 && '#444', margin: 0 }}>
                          {item.admin === 0 ? 'Default' : 'Admin'}
                        </Tag>
                      </div>
                      <div className="Order-Table_item text">{item.phone}</div>
                      <div className="Order-Table_item text">
                        {moment(item.createdAt).local().format("MMM Do YYYY")}
                      </div>
                      <div className="Order-Table_item text sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <Clickable defaultChecked={item.status ? true : false}
                            onClick={() => item.email !== 'admin@admin.com' && updateUser({
                              status_update: true, id: item.id, status: item.status ? false : true, userId: userId, userToken: userToken,
                              admin: admin
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {users?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
