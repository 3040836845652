import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Media'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getAllMedia: builder.query({
      query: (data) => ({
        url: `media/?page=${data.page}&size=${data.size}&searchid=${data.searchid}`,
        method: 'GET',
        headers: {
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.userRole,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      keepUnusedDataFor: 86400000,
      providesTags: ['Media'],
    }),

    updateMedia: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("myFile", data.image_file);
        return {
          url: `media`,
          method: 'POST',
          body: formData,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole
          }
        }
      },
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['Media'],
    }),

    deleteMedia: builder.mutation({
      query: (data) => {
        return {
          url: `media/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['Media'],
    }),



  }),

})

export const { useGetAllMediaQuery, useUpdateMediaMutation, useDeleteMediaMutation } = mediaApi