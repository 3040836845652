import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
// import { Line, Circle } from 'rc-progress';
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import { message, Switch as Clickable } from "antd";
import "antd/dist/antd.min.css";
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllMediaQuery, useUpdateMediaMutation, useDeleteMediaMutation } from '../../services/media';
import 'react-h5-audio-player/lib/styles.css';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ImageUpdate from "./ImageUpdate";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Select from 'react-select';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import Button from '@mui/material/Button';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Media() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false)
  const [isLoadingCreate, setLoadingCreate] = useState(false)
  const [isLoadingUpdate, setLoadingUpdate] = useState(false)
  const [isLoadingdoc, setLoadingdoc] = useState(false)
  const [show, setShow] = useState(false);

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const getLanguage = new URLSearchParams(location.search).get('language') || 'en'

  let languageGet
  if (getLanguage === null) {
    languageGet = '';
  } else {
    languageGet = `&language=${getLanguage}`
  }

  const [getSelectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    if (getSelectedLanguage?.value) {
      history.push(`${window.location.pathname}?language=${getSelectedLanguage?.value}`);
    }
  }, [getSelectedLanguage?.value]);

  const [getSearchId, setSearchId] = useState("")

  const responseInfo = useGetAllMediaQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  },
    { count: 1 },
    { refetchOnMountOrArgChange: true })
  const media = responseInfo?.data && responseInfo?.data?.media;
  const pagination = responseInfo?.data && responseInfo?.data?.pagination;


  const [getEditShow, setEditShow] = useState(false);
  const [getTitle, setTitle] = useState("")
  const [getSubTitle, setSubTitle] = useState("")
  const [getTextColor, setTextColor] = useState("")
  const [getUpdateId, updateId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setTitle(data.title)
    setSubTitle(data.subtitle)
    setTextColor(data.text_color)
    updateId(data.updateId)
  }

  const categoryData = {
    title: getTitle,
    subtitle: getSubTitle,
    text_color: getTextColor,
    id: getUpdateId,
    language: getLanguage,
    userId: userId,
    userToken: userToken,
    userRole: userRole,
  }



  const [deleteMedia, deleteresult] = useDeleteMediaMutation()





  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  const [updateImage, imageresult] = useUpdateMediaMutation()
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getImageError, setImageError] = useState("");
  const [getUpdateImage, setUpdateImage] = useState("");
  const [getImageBookId, setImageBookId] = useState("");


  
  function checkSize() {
    if ((getUpdateImage?.size === 1234468) || (getUpdateImage?.size < 1234468)) {
      return false
    } else if (getUpdateImage?.type === 'image/png') {
      return false
    } else if (getUpdateImage?.type === 'image/jpeg') {
      return false
    } else if (getUpdateImage?.type === 'image/jpg') {
      return false
    } else {
      return true
    }
  }




  function updateBookImage() {
    updateImage({
      image_file: getUpdateImage,
      category_id: getImageBookId,
      languageCode: getLanguage,
      userId: userId,
      userToken: userToken,
      userRole: userRole,
    })
  }
  useEffect(() => {
    if (imageresult.isSuccess) {
      setImageUpdateModal(false)
    }
  }, [imageresult]);
  function imageUpdate(data) {
    setImageUpdateModal(true)
    setImageBookId(data.category_id)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };


  function displayMediaFile(type, media) {
    if (type.includes('image')) {
      return (
        <img src={`https://cdn.ebtida.com/${media}`} width={'100%'} />
      )
    } else if (type.includes('video')) {
      return (
        <video width="320" height="240" controls>
          <source src={`https://cdn.ebtida.com/${media}`} type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
        </video>
      )
    }
  }





  function skeletonLoading() {
    return (
      <>
        <div className="col-md-3 media-card">
          <div className="media-subcard">
            <Skeleton variant="rect" height={'175px'} />
            <Skeleton variant="rect" height={'10px'} />
            <Skeleton variant="rect" height={'10px'} />
          </div>
        </div>
        <div className="col-md-3 media-card">
          <div className="media-subcard">
            <Skeleton variant="rect" height={'175px'} />
            <Skeleton variant="rect" height={'10px'} />
            <Skeleton variant="rect" height={'10px'} />
          </div>
        </div>
        <div className="col-md-3 media-card">
          <div className="media-subcard">
            <Skeleton variant="rect" height={'175px'} />
            <Skeleton variant="rect" height={'10px'} />
            <Skeleton variant="rect" height={'10px'} />
          </div>
        </div>
        <div className="col-md-3 media-card">
          <div className="media-subcard">
            <Skeleton variant="rect" height={'175px'} />
            <Skeleton variant="rect" height={'10px'} />
            <Skeleton variant="rect" height={'10px'} />
          </div>
        </div>
      </>
    )
  }



  return (
    <div className="Order-style category-page">



      <ImageUpdate
        getImageUpdateModal={getImageUpdateModal}
        closeImageUpdate={closeImageUpdate}
        getImageError={getImageError}
        setUpdateImage={setUpdateImage}
        imageresult={imageresult}
        updateBookImage={updateBookImage}
        getUpdateImage={getUpdateImage}
        checkSize={checkSize()}
      />



      {/* {responseInfo.isLoading && <div className="loadingBorder">&nbsp;</div>} */}
      <div className="container">
        <div className="row ">
          <div className="col-md-12">

            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                    {responseInfo?.isFetching &&
                      <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px', position: 'absolute', top: '12px', right: '40px', color: '#cbcbcb' }} animation="border" />
                    }
                    <SearchIcon className="table_search_icon" />
                  </div>

                </div>
                <div className="table_search_card">
                  <div>
                    <Button
                      color="warning"
                      variant="contained"
                      size="medium"
                      onClick={() => imageUpdate(true)}>
                      Add New
                    </Button>
                  </div>
                </div>
              </div>


              <div className="row" style={{ padding: '20px' }}>

                {responseInfo?.isLoading &&
                  skeletonLoading()
                }


                {media?.map((item, i) =>
                  <div className="col-md-3 media-card" key={i}>

                    <div className="media-subcard">
                      <div>
                        {displayMediaFile(item.type, item.name)}
                      </div>

                      <div style={{ wordBreak: 'break-all', fontSize: '12px' }}>
                        <a href={`https://cdn.ebtida.com/${item.name}`} target="_blank">
                          {`https://cdn.ebtida.com/${item.name}`}
                        </a>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '12px' }}>{item.createdAt}</div>

                        <div className="manage-buttons" style={{ justifyContent: 'right' }}>


                          {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ? 'deleting...' :
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={() => deleteMedia({
                                id: item.id,
                                name: item.name,
                                userId: userId,
                                userToken: userToken,
                                userRole: userRole,
                              })}
                            >
                              <DoDisturbOnIcon style={{ color: '#d04848' }} />
                            </IconButton>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>


              <div className="Order-Pagination">
                <Pagination
                  style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                  page={parseInt(page)}
                  count={parseInt(pagination?.totalPages)}
                  color="primary"
                  variant="outlined"
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                      {...item}
                    />
                  )}
                />
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
