import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllSegmentsQuery, useDeleteSegmentsMutation, useCreateSegmentsMutation, useUpdateSegmentsMutation } from '../../services/segments';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

function Segments() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const suppliersData = useGetAllSegmentsQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.segments;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteSegmentsMutation()
  const [createNew, createresult] = useCreateSegmentsMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getName, setName] = useState("")
  const [getEmail, setEmail] = useState("")
  const [getPhone, setPhone] = useState("")
  const [getAddress, setAddress] = useState("")
  const [getId, setId] = useState("")
  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setId(data.id)
  }

  const updateData = {
    id: getId,
    name: getName,
    userId: userId, userToken: userToken, admin: admin
  }
  const [updatePost, updateresult] = useUpdateSegmentsMutation()



  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };


  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }
    if (createresult?.isSuccess) {
      setShow(false)
      setChecked([])
    }
    if (deleteresult?.isSuccess) {
      setChecked([])
    }
  }, [updateresult, createresult, deleteresult]);

  return (
    <div className="Order-style">
      <Modal className="new_Modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {createresult?.error &&
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="alert alert-danger" style={{ textAlign: 'center' }}>{createresult?.error?.data?.error}</div>
                </div>
              </div>
            </div>
          }
          <form onSubmit={handleSubmit(createNew)}>
            <div className="row">
              <input type="hidden" id="userId" defaultValue={userId} aria-invalid={errors.userId ? "true" : "false"}
                {...register('userId', { required: false })} />
              <input type="hidden" id="userToken" defaultValue={userToken} aria-invalid={errors.userToken ? "true" : "false"}
                {...register('userToken', { required: false })} />
              <input type="hidden" id="admin" defaultValue={admin} aria-invalid={errors.admin ? "true" : "false"}
                {...register('admin', { required: false })} />
              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control dlrdflInput" id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">

                  <Button
                    color="info"
                    variant="contained"
                    size="medium"
                    disabled={createresult?.isLoading ? true : false}
                    type="submit"
                    style={{ width: '100%', height: '45px' }}
                  >
                    {createresult?.isLoading ? 'loading...' : 'Create'}
                  </Button>

                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal className="new_Modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control dlrdflInput" value={getName}
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  disabled={updateresult?.isLoading ? true : false}
                  onClick={() => { updatePost(updateData) }}>
                  {updateresult?.isLoading ? 'loading...' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                    <SearchIcon className="table_search_icon" />
                  </div>

                  {(suppliersData?.isFetching) && (!suppliersData?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>

                <div className="dFlex center nowrap">
                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => deleteList({
                      status: checked?.status ? 0 : 1,
                      id: checked?.id,
                      userId: userId,
                      userToken: userToken,
                      admin: admin
                    })}
                  >
                    {deleteresult?.isLoading && deleteresult?.originalArgs?.id === checked?.id ?
                      <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      :
                      'Delete'
                    }
                  </Button>
                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => handleOpenEdit({
                      status: true,
                      name: checked?.name,
                      id: checked?.id
                    })}
                  >
                    Edit
                  </Button>
                  <Button
                    color="warning"
                    variant="contained"
                    size="medium"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>

                </div>
              </div>



              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700" style={{ width: '0px' }}></div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">Created</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                  </div>
                  {suppliersData?.isLoading &&
                    <Skeletons />
                  }
                  {suppliers?.map((item, i) =>
                    <div className="Order-Table_subcard">
                      <div className="Order-Table_item text" style={{ width: '0px' }}>
                        <Checkbox
                          size="small"
                          checked={checked?.id === item.id ? checked?.event : false}
                          onChange={((event) => handleChange({
                            event: event.target.checked,
                            name: item.name,
                            id: item.id,
                            status: item.status
                          }))}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                      <div className="Order-Table_item text">
                        <Link to={`/segment/${item.id}?name=${item.name}`} disabled={item.status ? false : true}>
                          {item.name}
                        </Link>
                      </div>
                      <div className="Order-Table_item text">{moment(item.createdAt).local().format("MMM Do YYYY")}</div>
                      <div className="Order-Table_item text sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <Button
                            color="warning"
                            variant="contained"
                            size="small"
                            disabled
                          >
                            {item.status ? 'Active' : 'Inactive'}
                          </Button>

                        </div>
                      </div>
                    </div>
                  )}


                </div>
                {suppliers?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Segments;
