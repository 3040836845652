import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';

import { useGetAllPaymentsQuery, useRemovePaymentsMutation, useDefaultPaymentsMutation, useGetAllTransactionQuery, useChargeNowMutation } from '../../services/payments';
import { useGetAllBillingQuery, useUpdateUserMutation } from '../../services/payments';
import TableSkeletonPayment from "../TableSkeletonPayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Purchase/Checkout";
import { message, Switch as Clickable, Tag } from "antd";
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
import PaginationItem from '@mui/material/PaginationItem';


import Box from '@mui/material/Box';

const drawerWidth = 240;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const successMessage = () => {
  return (
    <div className="success-msg">
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
      <div className="title">New Payment Added Successfully</div>
    </div>
  )
}



function Biiling() {
  const { userId, userToken, userEmail, userName, languageCode, admin } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const { id } = useParams();

  if (!userEmail && !userToken) {
    history.push('/')
  }

  const userData = useGetAllBillingQuery({ id: id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const billing = userData?.data?.billing
  const customerNumber = userData?.data?.billing?.cid



  const responseInfo = useGetAllPaymentsQuery({ userId: userId, userToken: userToken, id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const paymentData = responseInfo?.data?.result?.data
  const customerData = responseInfo?.data?.customer



  const transactionInfo = useGetAllTransactionQuery({
    page: page,
    size: limit,
    id: id,
    userId: userId,
    userToken: userToken,
    admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const transactions = transactionInfo?.data?.transactions
  const pagination = transactionInfo?.data?.pagination;


  const [removePayment, removeResult] = useRemovePaymentsMutation()
  const [makeDefault, defaultResult] = useDefaultPaymentsMutation()


  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [updateUser, updateresult] = useUpdateUserMutation()

  const [getModal, setModal] = useState(false)
  function paymentModal() {
    setModal(true)
  }

  function closeModal() {
    setModal(false)
  }
  useEffect(() => {
    if (paymentCompleted) {
      setModal(false)
    }
  }, [paymentCompleted]);


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'urdu' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
  }


  function pmdbutton() {
    if (paymentData) {
      if (paymentData?.length > 2) {
        return true
      } else if (paymentData?.length === 2) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const [chargenow, createcharge] = useChargeNowMutation()



  return (
    <div className="Account-page tabel_cards">



      <Modal style={fontFamily_Text} className="purchase_modal" show={getModal} onHide={closeModal} centered animation={false}>
        <Modal.Body>
          <div className="Header-close-modal" style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }} onClick={closeModal}>
            <IconButton
              aria-haspopup="true"
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
          </div>
          <h5 style={{ padding: '20px', textAlign: 'center' }}>Payment Method</h5>
          {
            paymentCompleted ?
              successMessage() :
              <React.Fragment>
                <div className="col-md-12 order-md-1">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      customerNumber={customerNumber && customerNumber}
                      setPaymentCompleted={setPaymentCompleted}
                      getPaymentMethodForm={true}
                      id={id}
                    />
                  </Elements>
                </div>
              </React.Fragment>
          }
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="profile-card">

          <div className="row">
            <div className="col-md-12">
              {responseInfo?.isLoading &&
                <>
                  <Skeleton variant="rect" height={15} width={'40%'} />
                  <Skeleton variant="rect" height={15} width={'40%'} />
                </>
              }

              {!responseInfo?.isLoading &&
                <>
                  <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{billing?.name}</h5>
                  <div className='account-heading' style={{ paddingBottom: '15px' }}>{billing?.email}</div>
                  <div className='account-heading' style={{ paddingBottom: '15px' }}>{billing?.currency} {billing?.amount}</div>

                  <div>
                    <Clickable
                      disabled={!paymentData || paymentData?.length === 0}
                      defaultChecked={billing?.active}
                      onClick={() => updateUser({
                        status_update: true, id: billing?.id, active: billing?.active ? false : true, userId: userId, userToken: userToken,
                        admin: admin
                      })}
                    />
                  </div>
                </>
              }
            </div>
          </div>


          <div className="row cardList__margin">
            <div className="col-md-12">
              <div className='Profile-page-section'>
                <>
                  <div className="section-card">
                    <div className="jcsb ai df" style={{ justifyContent: 'space-between', padding: '20px 0px 20px 0px' }}>
                      <div>
                        <Button
                          disabled={createcharge?.isLoading || !billing?.active}
                          style={{ textTransform: 'capitalize' }}
                          color="info"
                          variant="outlined"
                          size="small"
                          onClick={(() => chargenow({
                            id: billing?.id,
                            userId: userId,
                            userToken: userToken,
                            admin: admin
                          }))}>{createcharge?.isLoading ? 'Loading...' : 'Charge Now'}</Button>
                      </div>
                      <div className="table_search_card">
                        {!responseInfo?.isLoading &&
                          <div className="newFlexPagi">
                            {!pmdbutton() &&
                              <Button
                                style={{ textTransform: 'capitalize' }}
                                color="warning"
                                variant="outlined"
                                size="small"
                                onClick={paymentModal}>New method</Button>
                            }

                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  {responseInfo?.isLoading &&
                    <>
                      <Skeleton variant="rect" height={15} width={'40%'} />
                      <Skeleton variant="rect" height={15} width={'40%'} />
                    </>
                  }
                  <div className="row">
                    {paymentData?.map((item, i) =>
                      <div className='col-md-5' key={i} style={{ display: 'flex' }}>
                        <div style={{ border: '1px solid #dedede', width: '500px', padding: '10px', borderRadius: '10px', marginBottom: '20px', height: '145px' }}>
                          <div>
                            <div style={{ textTransform: 'capitalize' }}>{item.card?.brand} - {item.card?.country}</div>
                            <div>**** **** **** {item.card?.last4}</div>
                          </div>
                          <div>
                            <div>{item.billing_details?.name}</div>
                          </div>
                          <div className="manage-buttons">
                            <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0px 10px 0px' }}>
                              {customerData?.invoice_settings?.default_payment_method === item.id ?
                                <Tag style={{ margin: 0 }} color={'#7fc687'}>Default</Tag>

                                :
                                <Button style={{ display: 'flex', marginRight: '10px' }}
                                  color="info"
                                  variant="outlined"
                                  size="small"
                                  onClick={() => makeDefault({ payment: item.id, customer: customerData?.id, country: item.card?.country, userId: userId, userToken: userToken, id: id })}>
                                  {defaultResult.isLoading && defaultResult?.originalArgs?.payment === item.id ?
                                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div> :
                                    <small>Make this default</small>}
                                </Button>
                              }
                              {customerData?.invoice_settings?.default_payment_method !== item.id &&
                                <Button style={{ display: 'flex' }}
                                  color="error"
                                  variant="outlined"
                                  size="small"
                                  onClick={() => removePayment({ payment: item.id, userToken: userToken, userId: userId, id: id })}>
                                  {removeResult.isLoading && removeResult?.originalArgs?.payment === item.id ?
                                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div> :
                                    <small>Remove</small>}
                                </Button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    }
                  </div>


                  <br /><br />

                  <h4>Transactions</h4>
                  <div className="row">
                    <div className='col-md-10' style={{ display: 'flex' }}>
                      <div className="tabelOverflow" style={{ width: '100%' }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{('Amount')}</th>
                              <th>{('Message')}</th>
                              <th>{('Date')}</th>
                              <th className="sticky-column" style={{ textAlign: 'right' }}>
                                {('status')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {transactionInfo?.isLoading &&
                                <Skeletons />
                              }
                            </>
                            {transactions?.map((item, i) =>
                              <tr key={i}>
                                <td>
                                  <div style={{ textTransform: 'capitalize' }}>{item.amount / 100}</div>
                                </td>
                                <td>
                                  <div>{item.message}</div>
                                </td>
                                <td>
                                  <div>{item.updatedAt}</div>
                                </td>
                                <td className="sticky-column" style={{ display: 'flex', justifyContent: 'right' }}>
                                  <div className="manage-buttons">
                                    {item.checkout === 1 ? 'Success' : 'Failed'}
                                  </div>
                                </td>
                              </tr>
                            )
                            }
                          </tbody>
                        </table>
                        {paymentData?.length === 0 &&
                          <div className="no-data-found"> No data found</div>
                        }

                      </div>

                    </div>
                  </div>
                  <div className="row">
                    <div className='col-md-10' style={{ display: 'flex' }}>
                      <div className="Order-Pagination">
                        <Pagination
                          style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                          page={parseInt(page)}
                          count={parseInt(pagination?.totalPages)}
                          color="primary"
                          variant="outlined"
                          renderItem={(item) => (
                            <PaginationItem
                              component={Link}
                              to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                              {...item}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                </>

              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Biiling;