import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Navbar,
} from "react-bootstrap";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useDispatch, useSelector } from "react-redux";
import { removeToCart } from "../../reducers/authReducer";
import { Menu, Dropdown } from 'antd';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PublicIcon from '@mui/icons-material/Public';
import SegmentIcon from '@mui/icons-material/Segment';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
const { SubMenu } = Menu;
const KEY = "ADD_PRODUCTS";

function HeaderMenu(props) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  function handleClick(e) {
  }
  const history = useHistory();
  const location = useLocation();

  function userLogout() {
    dispatch(removeToCart());
    history.push("/login");
  }

  const getName = new URLSearchParams(location.search).get('name')

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };


  const menu = (
    <Menu className="Header-dropdown-menu">
      <Menu.Item key="0">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/profile">Settings</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/profile">Change Password</Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={userLogout}>
        <Link to={'#'}>Sign out</Link>
      </Menu.Item>
    </Menu>
  );

  // let storeNamea = store_name ? userName.split(" ")[0] : null;
  const userNamea = userName?.split(" ")[0]?.slice(0, 8);

  const [getMobileClick, setMobileClick] = useState(true)
  function mobileMenuClick(data) {
    setMobileClick(data)
  }


  function onSubmit(data) {
    history.push(`/search/${data.emailid}`);
    reset()
  }


  return (
    <div className="headerMenu">

      <Menu onClick={handleClick} mode="vertical"
        inlineCollapsed={props.collapsed} className={`sideMenuN ${getMobileClick && 'classNone'}`}>

        <div className={`navTophdng strNumnv logoHeader ${props.collapsed && 'navTophdngcollapsed'} ${getMobileClick && 'classRelative'}`}>
          <span className="nameColor">
            <span className={`header_top-heading ${props.collapsed && 'hideHeaderName'}`}>
              <span style={{ display: 'flex', alignItems: 'center', color: '#d68934' }}>
                <span style={{ paddingRight: '2px' }}>
                  {/* <img src="../../user-thumbnail.png" style={{ borderRadius: "100px", width: "34px" }} /> */}

                </span>
                {userName ? userNamea : 'Dashboard'}
              </span>
              {/* <img src='/newwwwwhite.png' width={'38px'} /> */}
            </span>
            <CloseIcon className="desktop_hide" onClick={(() => mobileMenuClick(true))} />

            <IconButton
              className={`syncIcon mobile_hide ${props.collapsed && 'syncColor'}`}
              onClick={() => props.toggleCollapsed({ toggleCollapsed })}
              aria-haspopup="true"
            >
              <MenuIcon style={{ color: '#fcfcfc', fontSize: '24px' }} />
            </IconButton>

          </span>
        </div>

        <Menu.Item className="menuColor dFlex" key="11" icon={<HomeIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/", state: { from: "root" } }}>
            Dashboard
          </Link>
        </Menu.Item>

        <Menu.Item className="menuColor dFlex" key="111" icon={<ScheduleIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/campaigns", state: { from: "root" } }}>
            Campaigns
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="1112" icon={<PublicIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/countries", state: { from: "root" } }}>
            Countries
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="1113" icon={<SegmentIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/segments", state: { from: "root" } }}>
            Segments
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="1114" icon={<PeopleIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/subscribers", state: { from: "root" } }}>
            Subscribers
          </Link>
        </Menu.Item>
        <Menu.Item className="menuColor dFlex" key="1115" icon={<ArticleIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/templates", state: { from: "root" } }}>
            Templates
          </Link>
        </Menu.Item>

        <Menu.Item className="menuColor dFlex" key="52" icon={<SupervisorAccountIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/users", state: { from: "root" } }}>
            Team
          </Link>
        </Menu.Item>

        <Menu.Item className="menuColor dFlex" key="53" icon={<ImageIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/media", state: { from: "root" } }}>
            Media
          </Link>
        </Menu.Item>

        <Menu.Item className="menuColor dFlex" key="54" icon={<CreditCardIcon className="side-menu-icon" />}>
          <Link to={{ pathname: "/billings", state: { from: "root" } }}>
            Billing
          </Link>
        </Menu.Item>

      </Menu>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard">
          {/* <img src='/newwwwsssss.png' width={'40px'} /> */}
          <MenuIcon style={{ marginRight: '15px' }} className="desktop_hide" onClick={(() => mobileMenuClick(false))} />
          <div style={{ fontSize: '20px', color: '#222945', textTransform: 'capitalize', display: 'flex' }}>
            <span>{
              window.location.pathname.split('-').join(' ').split('/')[1] ?
                window.location.pathname.split('-').join(' ').split('/')[1] :
                'Dashboard'
            }</span>
            <small style={{ display: 'flex', alignItems: 'center', color: '#838383' }}>
              {getName && <><KeyboardArrowRightIcon /> {getName}</>}
            </small>
          </div>
        </div>
        <div className="headerRightCard">
          <form onSubmit={handleSubmit(onSubmit)} className="table_search_card">
            <input
              type="email"
              className="form-control table_search"
              placeholder="Search for user..."
              id="emailid"
              aria-invalid={errors.emailid ? "true" : "false"}
              {...register("emailid", { required: true })}
            />
            <IconButton aria-haspopup="true" type="submit" className="table_search_icon">
              <SearchIcon />
            </IconButton>
          </form>
          {/* <NotificationsIcon style={{ fontSize: '32px', color: '#21294563' }} className="mr20" /> */}
          <Dropdown className="Header-waller-connect2" overlay={menu} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              {/* <img src="../../user-thumbnail.png" style={{ borderRadius: "100px", width: "34px" }} /> */}
              <IconButton aria-haspopup="true">
                <SettingsIcon style={{ color: '#1e2f438f' }} />
              </IconButton>
            </a>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
}

export default HeaderMenu;
