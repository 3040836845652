// import React, { useState, useEffect, Fragment } from 'react';
// import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
// import { Spinner } from 'react-bootstrap'
// import { useForm } from "react-hook-form";
// import './index.scss';
// import { addToCart } from "../../reducers/authReducer";
// import { useLoginUserMutation } from '../../services/login';
// import { useDispatch, useSelector } from "react-redux";
// import Button from '@mui/material/Button';
// function Login() {
//   const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
//   const dispatch = useDispatch();
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const history = useHistory();

//   const [loginUser, loginresult] = useLoginUserMutation()
//   const loginData = loginresult?.data?.result;


//   const loginError = loginresult?.error?.data?.error;

//   useEffect(() => {
//     if (loginresult?.isSuccess) {
//       dispatch(addToCart({
//         userId: loginData?.id,
//         userToken: loginData?.user_token || '',
//         userEmail: loginData?.email || '',
//         userName: loginData?.name || '',
//         userRole: loginData?.user_role || '',
//         status: loginData?.status,
//         admin: loginData?.admin,
//       }));
//       // history.push('/dashboard');
//     }
//   }, [loginresult?.isSuccess]);

 
//   return (
//     <div className="Login-page">
//       {userToken && userId && <Redirect to="/" />}
//       <div className="container">
//         <div className="row loginRow" style={{background: '#eee'}}>
//           <div className="rowBox">
//             <div className="loginHeader">
//               <h4>Email Marketing</h4>
//             </div>
//             <div>
//               {loginError && <h6 className="alert alert-danger textMsgV"> {loginError}</h6>}
//             </div>
//             <form onSubmit={handleSubmit(loginUser)}>
//               <div className="form-group">
//                 <label>Email ID</label>
//                 <input className="form-control" type="email" id="email"
//                   aria-invalid={errors.email ? "true" : "false"}
//                   {...register('email', { required: true })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label>Password</label>
//                 <input
//                   type="password"
//                   className="form-control dlrdflInput"
//                   id="password"
//                   aria-invalid={errors.password ? "true" : "false"}
//                   {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
//                 {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
//                 {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
//                 {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
//               </div>
//               <Button
//                 color="info"
//                 variant="contained"
//                 size="medium"
//                 style={{ width: '100%', height: '45px' }}
//                 type="submit"
//                 disabled={loginresult.isLoading ? true : false}>{loginresult.isLoading ? 'Loading...' : 'Login'}</Button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Login;




































import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../reducers/authReducer";
import { useLoginUserMutation } from '../../services/login';

import Button from '@mui/material/Button';
import ReCAPTCHA from "react-google-recaptcha";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© '}
      {new Date().getFullYear()}
      {''}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();


function Login() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();

  const [loginUser, loginresult] = useLoginUserMutation()
  const loginData = loginresult?.data?.result;
  const loginError = loginresult?.error?.data?.error;

  useEffect(() => {
    if (loginresult?.isSuccess) {
      dispatch(addToCart({
        userId: loginData?.id,
        userToken: loginData?.user_token || '',
        userEmail: loginData?.email || '',
        userName: loginData?.name || '',
        userRole: loginData?.user_role || '',
        status: loginData?.status,
        admin: loginData?.admin,
      }));
      // history.push('/dashboard');
    }
  }, [loginresult?.isSuccess]);



  const recaptchaRef = React.useRef();

  async function loginUserForm(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
   
    await loginUser({
      email: data.email,
      password: data.password,
      captcha_token: token
    })
  }


  return (
    <div className="loginStyle">

      {userToken && userId && <Redirect to="/" />}
      <div className="container">
        <div className="row loginRow">
          <div className="rowBox" style={{ textAlign: 'center' }}>
            {/* <img src='https://cdn.ebtida.com/1690477357294-lit-logo-black.png' style={{ width: '80px' }} /> */}
            <h4>Email Marketing</h4>
            <ThemeProvider theme={defaultTheme} style={{ width: '100%', maxWidth: '100%', padding: 0 }}>
              <Container component="main" maxWidth="xs" className="login-form">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    {loginError && <h6 className="textMsgV" style={{ color: 'red' }}> {loginError}</h6>}
                  </div>
      

                  <Box className='cms-login-form' component="form" onSubmit={handleSubmit(loginUserForm)} noValidate sx={{ mt: 1 }}>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                    />

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      type="email"
                      aria-invalid={errors.email ? "true" : "false"}
                      {...register('email', { required: true })}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      aria-invalid={errors.password ? "true" : "false"}
                      {...register('password', { required: true, minLength: 8, maxLength: 16 })}
                    />
                    {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                    {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                    {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                    {/* <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    /> */}
                    <Button
                      style={{ width: '100%', height: '48px', background: '#353641', color: '#f7f7f7' }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="info"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={loginresult.isLoading ? true : false}
                    >
                      {loginresult.isLoading ? 'Loading...' : 'Sign In'}
                    </Button>

                  </Box>

                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
              </Container>
            </ThemeProvider>


          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

