import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
function ImageUpdate({ getImageUpdateModal, closeImageUpdate, setUpdateImage, imageresult, updateBookImage, getUpdateImage }) {

  return (
    <>
      <Modal
        className="image-update-modal"
        show={getImageUpdateModal}
        onHide={closeImageUpdate}
        animation={false}
        backdrop={imageresult?.isLoading ? 'static' : 'dynamic'}
      >
        <IconButton
          className="close-button"
          onClick={imageresult?.isLoading ? '' : closeImageUpdate}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        >
          <CloseIcon />
        </IconButton>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <h5>Update Media</h5>
              <br />
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input multiple type="file" className="form-control dlrdflInput"
                  onChange={(e) => setUpdateImage(e.target.files)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'center' }}>
                {imageresult.isLoading ?
                  <>
                    <Spinner style={{ width: '2rem', height: '2rem', fontSize: '7px', marginBottom: 10 }} animation="border" />
                    <h5>uploading... don't close or reload the browser </h5>
                  </>

                  :
                  <Button
                    color="info"
                    variant="contained"
                    size="medium"
                    style={{ width: '100%', height: '45px' }}
                    disabled={getUpdateImage ? false : true}
                    onClick={updateBookImage}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageUpdate;
