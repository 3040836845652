import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
 
import { loginApi } from '../services/login';
import { mediaApi } from '../services/media';
import { sessionsApi } from '../services/session';
import { UsersApi } from '../services/users';
import { campaignsApi } from '../services/campaigns';
import { campaignApi } from '../services/campaign';
import { countriesApi } from '../services/countries';
import { segmentsApi } from '../services/segments';
import { subscribersApi } from '../services/subscribers';
import { templatesApi } from '../services/templates';
import { indexApi } from '../services/index';
import { actionApi } from '../services/action';
import { searchApi } from '../services/search';
import { paymentsApi } from '../services/payments';
import { BillingApi } from '../services/billing';

import authReducer from "../reducers/authReducer";

export const store = configureStore({
  // reducerPath and reducer are created for us, which we can pass straight into the reducer parameter of configureStore.
  reducer: {
    auth: authReducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [segmentsApi.reducerPath]: segmentsApi.reducer,
    [subscribersApi.reducerPath]: subscribersApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [indexApi.reducerPath]: indexApi.reducer,
    [actionApi.reducerPath]: actionApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [BillingApi.reducerPath]: BillingApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
  },

  // middleware is also created for us, which will allow us to take advantage of caching, invalidation, polling, and the other features of RTK Query.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sessionsApi.middleware).concat(UsersApi.middleware).concat(loginApi.middleware).concat(mediaApi.middleware).concat(campaignsApi.middleware).concat(countriesApi.middleware).concat(segmentsApi.middleware).concat(subscribersApi.middleware).concat(templatesApi.middleware).concat(campaignApi.middleware).concat(indexApi.middleware).concat(actionApi.middleware).concat(searchApi.middleware).concat(paymentsApi.middleware).concat(BillingApi.middleware)
})


// It will enable to refetch the data on certain events, such as refetchOnFocus and refetchOnReconnect.
setupListeners(store.dispatch)
