import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Dashboard from './Pages/Dashboard';
import Users from './Pages/Users';
import Campaigns from './Pages/Campaigns';
import Campaign from './Pages/Campaign';
import Report from './Pages/Report';
import Countries from './Pages/Countries';
import Segments from './Pages/Segments';
import Segment from './Pages/Segment';
import Subscribers from './Pages/Subscribers';
import Search from './Pages/Search';
import Templates from './Pages/Templates';
import Opens from './Pages/Opens';
import Clicks from './Pages/Clicks';
import Unsubscribe from './Pages/Unsubscribe';
import Login from './Pages/Login';
import Profile from './Pages/Profile';
import Reports from './Pages/Reports';
import ChangePassword from './Pages/ChangePassword';
import Media from './Pages/Media';
import Billing from './Pages/Billing'
import Billings from "./Pages/Billings";

import Private from './Private';
import Public from './Public';
import NotFound from './NotFound';
import './App.css';


function App() {
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact path="/">
            <Private cmp={Dashboard} />
          </Route>
          <Route exact path="/opens/:campId/:userId">
            <Public pub={Opens} />
          </Route>
          <Route exact path="/clicks/:campId/:userId">
            <Public pub={Clicks} />
          </Route>
          <Route exact path="/unsubscribe/:campId/:userId">
            <Public pub={Unsubscribe} />
          </Route>
          <Route exact path="/campaigns">
            <Private cmp={Campaigns} />
          </Route>
          <Route exact path="/campaign/:campaign_id">
            <Private cmp={Campaign} />
          </Route>
          <Route exact path="/report/:campaign_id">
            <Private cmp={Report} />
          </Route>
          <Route exact path="/countries">
            <Private cmp={Countries} />
          </Route>
          <Route exact path="/media">
            <Private cmp={Media} />
          </Route>
          <Route exact path="/segments">
            <Private cmp={Segments} />
          </Route>
          <Route exact path="/segment/:segment_id">
            <Private cmp={Segment} />
          </Route>
          <Route exact path="/search/:emailid">
            <Private cmp={Search} />
          </Route>
          <Route exact path="/subscribers">
            <Private cmp={Subscribers} />
          </Route>
          <Route exact path="/billing/:id">
            <Private cmp={Billing} />
          </Route>
          <Route exact path="/billings">
            <Private cmp={Billings} />
          </Route>
          <Route exact path="/templates">
            <Private cmp={Templates} />
          </Route>
          <Route exact path="/change-password">
            <Private cmp={ChangePassword} />
          </Route>
          <Route exact path="/reports">
            <Private cmp={Reports} />
          </Route>
          <Route exact path="/users">
            <Private cmp={Users} />
          </Route>
          <Route exact path="/profile">
            <Private cmp={Profile} />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route>
            <Private cmp={NotFound} />
          </Route>
        </Switch>

      </Router>


    </div>
  );
}

export default App;
