import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
function ImageUpdate({ getImageUpdateModal, closeImageUpdate, setUpdateImage, updatefileresult, updateBookImage, getUpdateImage }) {

  return (
    <>
      <Modal
        className="image-update-modal"
        show={getImageUpdateModal}
        onHide={closeImageUpdate}
        animation={false}
        backdrop={updatefileresult?.isLoading ? 'static' : 'dynamic'}
      >
        <IconButton
          className="close-button"
          onClick={updatefileresult?.isLoading ? '' : closeImageUpdate}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        >
          <CloseIcon />
        </IconButton>
        <Modal.Body>
          
          <div className="row">
            <div className="col-md-12">
              <h5>Import CSV</h5>
              <br />
            </div>
 
            <div className="col-md-12">
            <h6><small>CSV Sample - without header</small></h6>
              <table className="table" style={{width: '100%', fontSize: '12px'}}>
                <tbody>
                <tr>
                    <th style={{border: '1px solid #eee', padding: '2px', color: '#eb8188'}}><span>name</span></th>
                    <th style={{border: '1px solid #eee', padding: '2px', color: '#eb8188'}}>email</th>
                    <th style={{ border: '1px solid #eee', padding: '2px', color: '#eb8188' }}>code</th>
                    <th style={{ border: '1px solid #eee', padding: '2px', color: '#eb8188' }}>mobile</th>
                    <th style={{border: '1px solid #eee', padding: '2px', color: '#eb8188'}}>subscribed</th>
                  </tr>
                  <tr>
                    <td style={{border: '1px solid #eee', padding: '2px'}}><span>example</span></td>
                    <td style={{border: '1px solid #eee', padding: '2px'}}>example@example.com</td>
                    <td style={{ border: '1px solid #eee', padding: '2px' }}>AE</td>
                    <td style={{ border: '1px solid #eee', padding: '2px' }}>12345</td>
                    <td style={{border: '1px solid #eee', padding: '2px'}}>1</td>
                  </tr>
                  <tr>
                    <td style={{border: '1px solid #eee', padding: '2px'}}><span>NULL</span></td>
                    <td style={{border: '1px solid #eee', padding: '2px'}}>example@example.com</td>
                    <td style={{ border: '1px solid #eee', padding: '2px' }}>NULL</td>
                    <td style={{ border: '1px solid #eee', padding: '2px' }}>NULL</td>
                    <td style={{border: '1px solid #eee', padding: '2px'}}>0</td>
                  </tr>
                </tbody>
              </table>
            </div> 



            <div className="col-md-12">
              <div className="form-group">
                <input multiple type="file" className="form-control dlrdflInput"
                  onChange={(e) => setUpdateImage(e.target.files[0])} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group" style={{ textAlign: 'center' }}>
                {updatefileresult.isLoading ?
                  <>
                    <Spinner style={{ width: '2rem', height: '2rem', fontSize: '7px', marginBottom: 10 }} animation="border" />
                    <h5>uploading... don't close or reload the browser </h5>
                  </>

                  :
                  <Button
                    color="info"
                    variant="contained"
                    size="medium"
                    style={{ width: '100%', height: '45px' }}
                    disabled={getUpdateImage ? false : true}
                    onClick={updateBookImage}>Update</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageUpdate;
