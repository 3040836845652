import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";

import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/billing';
import { message, Switch as Clickable } from "antd";
import Skeletons from './Skeletons'
import moment from 'moment'
import CheckoutForm from "../Purchase/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Menu, Dropdown, Tag } from 'antd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
function Billings() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10


  const [getNewModal, setNewModal] = useState(false)

  const [getPassword, setPassword] = useState('')

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    searchquery: getSearchQuery,
    userId: userId, userToken: userToken, admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.billing;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;



  const [creating, createresult] = useCreateUserMutation()

  const [getName, setName] = useState('');
  const [getEmail, setEmail] = useState('');
  const [getCurrency, setCurrency] = useState('');
  const [getAmount, setAmount] = useState('');
  const [getId, setId] = useState("")
 
  const [updateModal, setUpdateModal] = useState(false);
  function handleOpenEdit(data) {
    setUpdateModal(true)
    setName(data.name)
    setEmail(data.email)
    setCurrency(data.currency)
    setAmount(data.amount)
    setId(data.id)
  }

 
  const [updateUser, updateresult] = useUpdateUserMutation()

  function closeUpdateModal() {
    setUpdateModal(false)
  }
  

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  useEffect(() => {
    if (paymentCompleted) {
      setModal(false)
    }
  }, [paymentCompleted]);





  const [isCreateNew, setCreateNew] = useState(false);
  function createNew() {
    setCreateNew(true)
  }
  function closeCreateNew() {
    setCreateNew(false)
  }

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setUpdateModal(false)
    }
    if (createresult?.isSuccess) {
      setCreateNew(false)
    }
  }, [updateresult, createresult]);


  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);


  function buttondisabled() {
    if (!getAmount) {
      return true
    } else if (getAmount === 0) {
      return true
    } else if (createresult.isLoading) {
      return true
    } else if (updateresult.isLoading) {
      return true
    } else {
      return false
    }
  }


  return (
    <div className="Order-style">



      <Modal className="purchase_modal" show={isCreateNew} onHide={closeCreateNew} centered animation={false}>
        <Modal.Body>
          <div className="Header-close-modal" style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }} onClick={closeCreateNew}>
            <IconButton
              aria-haspopup="true"
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
          </div>
          <h5 style={{ padding: '20px', textAlign: 'center' }}>New</h5>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name</label>
                <input type='text' className='form-control' onChange={((e) => setName(e.target.value))} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Email</label>
                <input type='email' className='form-control' onChange={((e) => setEmail(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Currency</label>
                <input type='text' className='form-control' defaultValue={getCurrency ? getCurrency : 'AED'} onChange={((e) => setCurrency(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Amount</label>
                <input type='number' className='form-control' onChange={((e) => setAmount(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <Button
                  disabled={buttondisabled()}
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  onClick={(() => !buttondisabled() && creating({
                    name: getName,
                    email: getEmail,
                    currency: getCurrency ? getCurrency : 'AED',
                    amount: getAmount,
                    userId: userId,
                    userToken: userToken,
                    admin: admin
                  }))}
                >
                  {createresult.isLoading ? 'loading...' : 'Create'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <Modal className="purchase_modal" show={updateModal} onHide={closeUpdateModal} centered animation={false}>
        <Modal.Body>
          <div className="Header-close-modal" style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }} onClick={closeUpdateModal}>
            <IconButton
              aria-haspopup="true"
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
          </div>
          <h5 style={{ padding: '20px', textAlign: 'center' }}>New</h5>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name</label>
                <input type='text' className='form-control' defaultValue={getName}  onChange={((e) => setName(e.target.value))} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Email</label>
                <input type='email' className='form-control' defaultValue={getEmail} onChange={((e) => setEmail(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Currency</label>
                <input type='text' className='form-control' defaultValue={getCurrency ? getCurrency : 'AED'} onChange={((e) => setCurrency(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Amount</label>
                <input type='number' className='form-control' defaultValue={getAmount} onChange={((e) => setAmount(e.target.value))} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <Button
                  disabled={buttondisabled()}
                  color="info"
                  variant="contained"
                  size="medium"
                  style={{ width: '100%', height: '45px' }}
                  onClick={(() => !buttondisabled() && updateUser({
                    name: getName,
                    email: getEmail,
                    currency: getCurrency ? getCurrency : 'AED',
                    amount: getAmount,
                    userId: userId,
                    userToken: userToken,
                    admin: admin,
                    id: getId,
                    update_billing: true,
                  }))}
                >
                  {createresult.isLoading ? 'loading...' : 'Create'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>





      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                  </div>

                  {(usersInfo?.isFetching) && (!usersInfo?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
                <div className="dFlex center nowrap">
                  <Button
                    color="warning"
                    variant="contained"
                    size="medium"
                    onClick={() => createNew(true)}>
                    Add New
                  </Button>

                </div>
              </div>


              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700">ID</div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">Email</div>
                    <div className="Order-Table_item heading fw700">Currency</div>
                    <div className="Order-Table_item heading fw700">Amount</div>
                    <div className="Order-Table_item heading fw700">Date</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                  </div>
                  {usersInfo?.isLoading &&
                    <Skeletons />
                  }
                  {users?.map((item, i) =>
                    <div className="Order-Table_subcard">
                      <div className="Order-Table_item text">{item.id}</div>
                      <div className="Order-Table_item text">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            onClick={(() => handleOpenEdit(item))}
                          >
                            <EditIcon style={{fontSize: '1rem'}} className="Header-close-modal-icon" />
                          </IconButton>
                          <Link to={`/billing/${item.id}`}>
                            {item.name}
                          </Link>
                        </div>
                      </div>
                      <div className="Order-Table_item text">{item.email}</div>

                      <div className="Order-Table_item text">{item.currency}</div>
                      <div className="Order-Table_item text">{item.amount}</div>
                      <div className="Order-Table_item text">
                        {moment(item.createdAt).local().format("MMM Do YYYY")}
                      </div>
                      <div className="Order-Table_item text sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <Clickable disabled defaultChecked={item.active ? true : false} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {users?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billings;
