import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'

import { useUnsubscribeUpdateMutation } from '../../services/action';

import Button from '@mui/material/Button';
function Unsubscribe() {
  const { campId, userId } = useParams();
  const [updatedata, updateresult] = useUnsubscribeUpdateMutation()


  useEffect(() => {
    updatedata({
      campId: campId,
      userId: userId
    })

    setTimeout(function() {
      window.location.replace('https://desertcart.com');
    }, 5000);
  }, []);



  return (

    <div className="Login-page">
      <div className="container">

        <div className="container">
          <div className="row loginRow" style={{ background: '#eee' }}>
            <div className="rowBox">
              <div className="loginHeader" style={{ justifyContent: 'center', padding: '30px' }}>
                {!updateresult?.data?.result?.subscribed &&
                  <h4>
                    Successfully Unsubscribed!
                  </h4>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



  );
}

export default Unsubscribe;
