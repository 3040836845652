import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useFetchQuery, useDeleteMutation, useCreateMutation, useUpdateMutation, useUpdateMediaMutation } from '../../services/templates';
import { useSelector } from "react-redux";
import Skeletons from './Skeletons'
import moment from 'moment'
import SearchIcon from "@material-ui/icons/Search";

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PhotoIcon from '@mui/icons-material/Photo';
import { CKEditor } from 'ckeditor4-react';
import ImageUpdate from "./ImageUpdate";

import parse from "html-react-parser";
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
import PaginationItem from '@mui/material/PaginationItem';
function Templates() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const suppliersData = useFetchQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.templates;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteMutation()
  const [createNew, createresult] = useCreateMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);


  const [getName, setName] = useState("")
  const [getTemplateHtml, setTemplateHtml] = useState("")
  const [getId, setId] = useState()


  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(data.status)
    setName(data.name)
    setTemplateHtml(data.template_html)
    setId(data.id)

  }

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }


  const [updatePost, updateresult] = useUpdateMutation()








  const postFeatures = (evt) => {
    const data = evt.editor.getData();
    setFeatures(data);
  }

  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setTemplateHtml(data);
  }

  function createNewPost(data) {
    createNew({
      name: data.name,
      template_html: getTemplateHtml,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }


  const updateData = {
    name: getName,
    template_html: getTemplateHtml,
    userId: userId,
    userToken: userToken,
    admin: admin,
    id: getId,
    update: 'update'
  }



  const [updateImage, imageresult] = useUpdateMediaMutation()
  const [getImageUpdateModal, setImageUpdateModal] = useState(false);
  const [getUpdateImage, setUpdateImage] = useState();
  const [getPostId, setPostId] = useState();






  function updateBookImage() {
    updateImage({
      image_file: getUpdateImage,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      id: getPostId
    })
  }


  const [getViewTemp, setViewTemp] = React.useState(false);
  const [getTemplate, setTemplate] = React.useState("");

  const ViewTemplateModal = (data) => {
    setViewTemp(true);
    setTemplate(data);
  };
  const closeViewTemp = () => {
    setViewTemp(false);
  };


  function imageUpdate(data) {
    setImageUpdateModal(true)
    setPostId(data)
  }
  function closeImageUpdate() {
    setImageUpdateModal(false)
  }

  const [checked, setChecked] = React.useState([]);

  const handleChange = (data) => {
    setChecked(data);
  };

  useEffect(() => {
    if (imageresult.isSuccess) {
      setImageUpdateModal(false)
    }
    if (updateresult?.isSuccess) {
      setEditShow(false)
      setChecked([])
    }
    if (createresult?.isSuccess) {
      setShow(false)
      setChecked([])
    }
    if (deleteresult?.isSuccess) {
      setChecked([])
    }

  }, [imageresult, updateresult, createresult, deleteresult]);


  return (
    <div className="Order-style">
      <div style={{ display: 'none' }}>
        <CKEditor
          style={{ display: 'none' }}
          data={``}
          config={{
            extraPlugins: "justify,showblocks",
            toolbar: [
              ['Source'],
              ['Styles', 'Format', 'Font', 'FontSize'],
              ['Bold', 'Italic'],
              ['Undo', 'Redo'],
              ['EasyImageUpload'],
              ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
              ['Maximize']
            ],
            height: '100vh',
            allowedContent: true,
            direction: 'rtl',
            language: 'en',
            pasteFromWordRemoveFontStyles: false
          }}
          id="template_html"
        />
      </div>



      <ImageUpdate
        getImageUpdateModal={getImageUpdateModal}
        closeImageUpdate={closeImageUpdate}
        setUpdateImage={setUpdateImage}
        imageresult={imageresult}
        updateBookImage={updateBookImage}
        getUpdateImage={getUpdateImage}
      />




      <Modal className="new-post-modal" show={getViewTemp} onHide={closeViewTemp} centered animation={false}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="post-value"
                dangerouslySetInnerHTML={{
                  __html: getTemplate
                }}
              >
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>










      <Modal className="new-template-modal" show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createNewPost)}>

            <div className="row">
              <div className="col-md-4" style={{ borderRight: '1px solid #eee' }}>
                <div className="form-group">
                  <label>Fallback Tags</label>
                  <div className='html-fallback-tag'>
                    <div className='html-fallback-card'>
                      <span style={{ paddingRight: '7px' }}><strong>Name:</strong></span>
                      <span style={{ color: '#ed6d03' }}>{`{{name}}`}</span>
                    </div>
                    <div className='html-fallback-card'>
                      <span style={{ paddingRight: '7px' }}><strong>Email:</strong></span>
                      <span style={{ color: '#ed6d03' }}>{`{{email}}`}</span>
                    </div>
                    <div className='html-fallback-card'>
                      <span style={{ paddingRight: '7px' }}><strong>Unsubscribe Link:</strong></span>
                      <span style={{ color: '#ed6d03' }}>{`{{unsubscribe}}`}</span>
                    </div>
                    <div className='html-fallback-card'>
                      <span style={{ paddingRight: '7px' }}><strong>Currency:</strong></span>
                      <span style={{ color: '#ed6d03' }}>{`{{currency}}`}</span>
                    </div>
                    <div className='html-fallback-card'>
                      <span style={{ paddingRight: '7px' }}><strong>Amount 1:</strong></span>
                      <span style={{ color: '#ed6d03' }}>{`{{amount1}}`}</span>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" className="form-control dlrdflInput" id="name"
                        aria-invalid={errors.name ? "true" : "false"}
                        {...register('name', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Newsletter</label>
                      <CKEditor
                        data={``}
                        config={{
                          extraPlugins: "justify,showblocks",
                          toolbar: [
                            ['Source'],
                            ['Styles', 'Format', 'Font', 'FontSize'],
                            ['Bold', 'Italic'],
                            ['Undo', 'Redo'],
                            ['EasyImageUpload'],
                            ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                            ['Maximize']
                          ],
                          height: '100vh',
                          allowedContent: true,
                          direction: 'rtl',
                          language: 'en',
                          pasteFromWordRemoveFontStyles: false
                        }}
                        id="template_html"
                        onChange={postDetails}
                        initData={parse(``)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Button disabled={createresult?.isLoading ? true : false}
                        color="info"
                        variant="contained"
                        size="medium"
                        style={{ width: '100%', height: '45px' }}
                        type="submit">
                        {createresult?.isLoading ? 'Loading...' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>






      <Modal className="new-template-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-md-4" style={{ borderRight: '1px solid #eee' }}>
              <div className="form-group">
                <label>Fallback Tags</label>
                <div className='html-fallback-tag'>
                  <div className='html-fallback-card'>
                    <span style={{ paddingRight: '7px' }}><strong>Name:</strong></span>
                    <span style={{ color: '#ed6d03' }}>{`{{name}}`}</span>
                  </div>
                  <div className='html-fallback-card'>
                    <span style={{ paddingRight: '7px' }}><strong>Email:</strong></span>
                    <span style={{ color: '#ed6d03' }}>{`{{email}}`}</span>
                  </div>
                  <div className='html-fallback-card'>
                    <span style={{ paddingRight: '7px' }}><strong>Unsubscribe Link:</strong></span>
                    <span style={{ color: '#ed6d03' }}>{`{{unsubscribe}}`}</span>
                  </div>
                  <div className='html-fallback-card'>
                    <span style={{ paddingRight: '7px' }}><strong>Currency:</strong></span>
                    <span style={{ color: '#ed6d03' }}>{`{{currency}}`}</span>
                  </div>
                  <div className='html-fallback-card'>
                    <span style={{ paddingRight: '7px' }}><strong>Amount 1:</strong></span>
                    <span style={{ color: '#ed6d03' }}>{`{{amount1}}`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control dlrdflInput" defaultValue={getName} onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Newsletter</label>
                    <CKEditor
                      data={``}
                      config={{
                        extraPlugins: "justify,showblocks",
                        toolbar: [
                          ['Source'],
                          ['Styles', 'Format', 'Font', 'FontSize'],
                          ['Bold', 'Italic'],
                          ['Undo', 'Redo'],
                          ['EasyImageUpload'],
                          ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                          ['Maximize']
                        ],
                        height: '100vh',
                        allowedContent: true,
                        direction: 'rtl',
                        language: 'en',
                        pasteFromWordRemoveFontStyles: false
                      }}
                      id="template_html"
                      onChange={postDetails}
                      initData={getTemplateHtml} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Button disabled={updateresult?.isLoading ? true : false}
                      color="info"
                      variant="contained"
                      size="medium"
                      style={{ width: '100%', height: '45px' }}
                      onClick={() => { updatePost(updateData) }}
                    >
                      {updateresult?.isLoading ? 'Loading...' : 'Update'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ border: '1px solid #dfdfdf', borderRadius: '4px' }}>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="table_search_card">
                    <input className="form-control table_search" placeholder="Search..." onChange={(e) => searchQuery(e.target.value)} />
                    <SearchIcon className="table_search_icon" />
                  </div>

                  {(suppliersData?.isFetching) && (!suppliersData?.isLoading) &&
                    <div className="bouncing-loader" style={{ paddingLeft: '15px', paddingTop: '4px' }}>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
                <div className="dFlex center nowrap">

                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => deleteList({
                      status: checked?.status ? 0 : 1,
                      id: checked?.id,
                      userId: userId,
                      userToken: userToken,
                      admin: admin
                    })}
                  >
                    {deleteresult?.isLoading && deleteresult?.originalArgs?.id === checked?.id ?
                      <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      :
                      'Delete'
                    }
                  </Button>

                  <Button
                    disabled={checked?.event ? false : true}
                    variant="outlined"
                    size="medium"
                    className="mr10"
                    onClick={() => handleOpenEdit({
                      status: true,
                      name: checked.name,
                      template_html: checked.template_html,
                      id: checked.id
                    })}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="contained"
                    size="medium"
                    color="warning"
                    onClick={() => handleOpen(true)}>
                    Add New
                  </Button>

                </div>
              </div>



              <div className="Order-Table_view">
                <div className="Order-Table_card">
                  <div className="Order-Table_subcard" style={{ marginBottom: 0 }}>
                    <div className="Order-Table_item heading fw700" style={{ width: '0px' }}></div>
                    <div className="Order-Table_item heading fw700">Name</div>
                    <div className="Order-Table_item heading fw700">Created</div>
                    <div className="Order-Table_item heading fw700 sticky-column tar">Status</div>
                  </div>
                  {suppliersData?.isLoading &&
                    <Skeletons />
                  }
                  {suppliers?.map((item, i) =>
                    <div className="Order-Table_subcard">
                      <div className="Order-Table_item text" style={{ width: '0px' }}>
                        <Checkbox
                          size="small"
                          checked={checked?.id === item.id ? checked?.event : false}
                          onChange={((event) => handleChange({
                            event: event.target.checked,
                            name: item.name,
                            template_html: item.template_html,
                            id: item.id,
                            status: item.status
                          }))}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                      <div className="Order-Table_item text">
                        {item.name}
                      </div>
                      <div className="Order-Table_item text">
                        {moment(item.updatedAt).local().format("MMMM Do YYYY")}
                      </div>
                      <div className="Order-Table_item text sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <Button
                            onClick={() => ViewTemplateModal(item.template_html)}
                            color={`info`}
                            variant="contained"
                            size="small"
                          >
                            <small className="dFlex">{`View Template`}</small>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}


                </div>
                {suppliers?.length === 0 &&
                  <h1 className="Order-no_data_found">Not Data Found!</h1>
                }
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '20px 0px 20px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Templates;
