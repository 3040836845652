import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'

import { useGetUserQuery, useUpdateUserMutation } from '../../services/users';

import Button from '@mui/material/Button';
function Profile() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, admin } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [updateUser, updateresult] = useUpdateUserMutation()


  async function changePassword(data) {
    await updateUser({
      current_password: data.current_password,
      new_password: data.new_password,
      userId: userId,
      id: userId,
      userToken: userToken,
      userRole: userRole,
      admin: admin
    })
  }

  const userInfo = useGetUserQuery({
    userId: userId, userToken: userToken, admin: admin
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const user = userInfo?.data && userInfo?.data?.user;


  return (

    <div className="Profile-style">
      <div className="container">

        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-md-6">
            <div style={{ background: '#fcfcfc', padding: '20px', borderRadius: '4px', border: '1px solid #eee' }}>
              <div className="df ac jcsb w100">
                <h4 className="m0"><strong>Update Profile</strong></h4>
              </div>
              <br />
              <div className="Profile-section">
                <div className="Profile-card">
                  <div className="Profile-item heading fw700">Name</div>
                  <div className="Profile-item heading">{userInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : user?.name}</div>
                </div>
                <div className="Profile-card">
                  <div className="Profile-item heading fw700">Email</div>
                  <div className="Profile-item heading">{userInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : user?.email}</div>
                </div>
                <div className="Profile-card">
                  <div className="Profile-item heading fw700">Phone</div>
                  <div className="Profile-item heading">{userInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> : user?.phone}</div>
                </div>
              </div>
              <div className="Order-Table_view" style={{ padding: '0px' }}>
                <div className="Order-Table_card">
                  <div className="section-card p20 bt-none">
                    <div className="change-password-card">
                      {updateresult?.error &&
                        <div className="col-md-12" style={{ textAlign: "center" }}>
                          <div className="alert alert-danger">{updateresult?.error?.data?.error}</div>
                        </div>}
                      {updateresult?.data &&
                        <div className="col-md-12" style={{ textAlign: "center" }}>
                          <div className="alert alert-success">{updateresult?.data?.success}</div>
                        </div>}

                      <form onSubmit={handleSubmit(changePassword)}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Current Password</label>
                              <input type="password" className="form-control dlrdflInput"
                                id="current_password"
                                style={{ height: '45px' }}
                                aria-invalid={errors.current_password ? "true" : "false"}
                                {...register('current_password', { required: true })}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>New Password</label>
                              <input
                                type="password"
                                className="form-control dlrdflInput"
                                id="new_password"
                                style={{ height: '45px' }}
                                aria-invalid={errors.new_password ? "true" : "false"}
                                {...register('new_password', { required: true, minLength: 8, maxLength: 16 })} />
                              {errors.new_password && errors.new_password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                              {errors.new_password && errors.new_password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                              {errors.new_password && errors.new_password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <Button
                                disabled={updateresult?.isLoading ? true : false}
                                color="warning"
                                variant="contained"
                                size="medium"
                                type="submit"
                                style={{ width: '100%', height: '45px' }}
                              >
                                {updateresult?.isLoading ? 'Loading...' : 'Change Password'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Profile;
