import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetCampaignQuery } from '../../services/campaign';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tag } from 'antd';
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CSVLink, CSVDownload } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
var moment = require('moment');
function Report() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { campaign_id } = useParams();
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const campaignInfo = useGetCampaignQuery({ id: campaign_id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const Campaign = campaignInfo?.data?.campaign;

  const countOpens = [...new Set(Campaign?.opens?.split(',')?.map((item) => item))]
  const countClicks = [...new Set(Campaign?.clicked?.split(',')?.map((item) => item))]
  const countUnsubscribe = [...new Set(Campaign?.unsubscribed?.split(',')?.map((item) => item))]

  const currenctDate = moment(new Date());//now
  const scheduledDate = moment(Campaign?.send_date);

  const totalDays = currenctDate?.diff(scheduledDate, 'days')

  const downloadData = () => [
    {
      campaign_name: Campaign?.name,
      segment_id: Campaign?.segment_id,
      template_id: Campaign?.template_id,
      subject: Campaign?.subject,
      from_name: Campaign?.from_name,
      from_email: Campaign?.from_email,
      reply_email: Campaign?.reply_toemail,
      to_send: Campaign?.to_send,
      to_contacts: Campaign?.to_contacts,
      opens: countOpens?.length,
      opens_percentage: Campaign?.to_send ? ((parseInt(countOpens?.length) / parseInt(Campaign?.to_send)) * 100).toFixed(2) + '%' : '',
      clicked: countClicks?.length,
      clicked_percentage: Campaign?.to_send ? ((parseInt(countClicks?.length) / parseInt(Campaign?.to_send)) * 100).toFixed(2) + '%' : '',
      unsubscribed: countUnsubscribe?.length,
      unsubscribed_percentage: Campaign?.to_send ? ((parseInt(countUnsubscribe?.length) / parseInt(Campaign?.to_send)) * 100).toFixed(2) + '%' : '',
      schedule_date: moment.utc(Campaign?.schedule_date).format("MMM Do YYYY hh:mm"),
      send_date: moment.utc(Campaign?.send_date).format("MMM Do YYYY"),
      send_status: Campaign?.send_status
    }
  ];



  return (
    <div className="Order-style">

      <div className="dashboardStyle">
        <div className="card card-dashboard-seven" style={{ padding: '20px', backgroundColor: '#f7f7f7' }}>
          <div className="container">
            <div className="db_welcome-msg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Button
                    onClick={() => history.push(`/campaigns`)}
                    color={`warning`}
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: '10px' }}
                  >
                    <small className="dFlex"><ArrowBackIcon style={{ fontSize: '18px' }} /> &nbsp;{`Go to campaign`}</small>
                  </Button>

                  {!Campaign?.send_status ?
                    <>
                      {Campaign?.starts ?
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '7px' }}>
                          <span style={{ paddingRight: '5px' }}>Sending...</span>
                          <Spinner style={{ width: '1rem', height: '1rem', fontSize: '7px' }} animation="border" />
                        </div> : ''
                      }
                    </> : ''
                  }
                </div>
                <h2>{Campaign?.name}</h2>
              </div>



            </div>
            <br /><br />

            <div className="card-body-db">
              <div className="row">
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Opens</label>
                  <div>
                    {campaignInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                      <>

                        <Tag color={"#76c1ce"} style={{ color: '#fcfcfc' }}>
                          <h4 style={{ color: '#fcfcfc' }}>{countOpens?.length}</h4>
                        </Tag>
                      </>
                    }
                  </div>
                  <span><strong>{Campaign?.to_send ? ((parseInt(countOpens?.length) / parseInt(Campaign?.to_send)) * 100).toFixed(2) + '%' : ''}</strong>&nbsp; ({totalDays} days)</span>
                </div>
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Clicks</label>
                  <div>
                    {campaignInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                      <>
                        <Tag color={"#72c585"} style={{ color: '#fcfcfc' }}>
                          <h4 style={{ color: '#fcfcfc' }}>{countClicks?.length}</h4>
                        </Tag>
                      </>
                    }
                  </div>
                  <span><strong>{Campaign?.to_send ? ((parseInt(countClicks?.length) / parseInt(Campaign?.to_send)) * 100).toFixed(2) + '%' : ''}</strong>&nbsp; ({totalDays} days)</span>
                </div>
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Unsubscribed</label>
                  <div>
                    {campaignInfo?.isLoading ? <Skeleton variant="rect" height={`15px`} /> :
                      <>
                        <Tag color={"#eb8188"} style={{ color: '#fcfcfc' }}>
                          <h4 style={{ color: '#fcfcfc' }}>{countUnsubscribe?.length}</h4>
                        </Tag>
                      </>
                    }
                  </div>
                  <span><strong>{Campaign?.to_send ? ((parseInt(countUnsubscribe?.length) / parseInt(Campaign?.to_send)) * 100).toFixed(2) + '%' : ''}</strong>&nbsp; ({totalDays} days)</span>
                </div>
                <div className="col-6 col-lg-3 seven-content-db">
                  <label>Bounced </label>
                  <div>
                    <Tag color={"#fed374"} style={{ color: '#fcfcfc' }}>
                      <h4 style={{ color: '#fcfcfc' }}>0.0</h4>
                    </Tag>
                  </div>
                  <span><strong>0.0%</strong>&nbsp; ({totalDays} days)</span>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      <div style={{ width: '100%', padding: '30px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <CSVLink data={downloadData()} filename={Campaign?.name?.split(' ')?.join('_')} target="_blank">
            <Button
              color={`info`}
              variant="outlined"
              size="small"
              style={{ marginBottom: '10px' }}
            >
              <small className="dFlex"><DownloadIcon style={{ fontSize: '18px' }} /> &nbsp;{`Download Report`}</small>
            </Button>
          </CSVLink>
        </div>
      </div>


      <div className="dashboardStyle">
        <div className="card card-dashboard-seven" style={{ padding: '20px', backgroundColor: '#f7f7f7' }}>
          <div className="container">
            <div className="db_welcome-msg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <h4>{`Links Clicked`}</h4>
              </div>

            </div>
            <br /><br />

            <div className="card-body-db">
              <div className="row">



              </div>
            </div>
          </div>

        </div>
      </div>





    </div>
  );
}

export default Report;
